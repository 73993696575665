import { SvgIcon, SvgIconProps, makeStyles } from "@material-ui/core"

export const PageIcon = ({ pageCode }: { pageCode: string }) => {
    const styles = useStyles()
    return <div className={styles.size}>
        {['ONS010REGULAR', 'ONS010SPOT', 'ONS010SOWC'].includes(pageCode) ? <PlaceOrderIcon className={styles.icon} /> : <></>}
        {['ONS030ORDER', 'ONS030FORECAST', 'ONS030ORDERWC', 'OCS027', 'ORS020', 'ONS040ORDER', 'ONS040FORECAST'].includes(pageCode) ? <ChangeOrderForecastIcon className={styles.icon} /> : <></>}
        {['OCS022', 'ORS010'].includes(pageCode) ? <ChangeCancelRequestListIcon className={styles.icon} /> : <></>}
        {pageCode === 'OCS010' ? <CoMonitoringListIcon className={styles.icon} /> : <></>}
        {['OPS010', 'OCS030', 'OPS020', 'OSS020', 'TDL010'].includes(pageCode) ? <PoMonitoringListIcon className={styles.icon} /> : <></>}
        {pageCode === 'OSS010' ? <SoMonitoringListIcon className={styles.icon} /> : <></>}
        {pageCode === 'LIS010' ? <InboundMonitoringListIcon className={styles.icon} /> : <></>}
        {pageCode === 'LOS011' ? <DownloadDeliveryPlanIcon className={styles.icon} /> : <></>}
        {pageCode === 'LOS020' ? <DownloadOutboundInstructionIcon className={styles.icon} /> : <></>}
        {['LOS030', 'LOS040'].includes(pageCode) ? <OutboundMonitoringListIcon className={styles.icon} /> : <></>}
        {pageCode === 'CIS010' ? <CargoTrackingDetailIcon className={styles.icon} /> : <></>}
        {pageCode === 'CIS011' ? <CargoTrackingIcon className={styles.icon} /> : <></>}
        {pageCode === 'LSS010' ? <ShippingDetailListIcon className={styles.icon} /> : <></>}
        {['LDS010', 'LDS010BU'].includes(pageCode) ? <CustomerDiListIcon className={styles.icon} /> : <></>}
        {['AIS030', 'AIS040'].includes(pageCode) ? <CustomerInvoiceListIcon className={styles.icon} /> : <></>}
        {pageCode === 'AIS010' ? <BuyerInvoiceListIcon className={styles.icon} /> : <></>}
        {pageCode === 'AIS020' ? <SellerInvoiceListIcon className={styles.icon} /> : <></>}
        {pageCode === 'AIS060' ? <AccountsReceivableIcon className={styles.icon} /> : <></>}
        {pageCode === 'MTS020' ? <PartsInventoryListByPartsIcon className={styles.icon} /> : <></>}
        {pageCode === 'MTS030' ? <PartsInventoryListByPackageIcon className={styles.icon} /> : <></>}
        {pageCode === 'MTS040' ? <DownloadInboundPlanIcon className={styles.icon} /> : <></>}
        {pageCode === 'MTS050' ? <DownloadOverallVisualisationIcon className={styles.icon} /> : <></>}
        {['DAS010', 'DAS012', 'DAS013', 'DAS014', 'DAS015'].includes(pageCode) ? <DashboardIcon className={styles.icon} /> : <></>}
        {pageCode === 'MLS010' ? <CompanyListScreenIcon className={styles.icon} /> : <></>}
        {pageCode === 'MLS020' ? <BusinessUnitListScreenIcon className={styles.icon} /> : <></>}
        {pageCode === 'MLS030' ? <CustomerListIcon className={styles.icon} /> : <></>}
        {pageCode === 'MLS040' ? <SupplierListIcon className={styles.icon} /> : <></>}
        {pageCode === 'MLS050' ? <DistributionCentreListIcon className={styles.icon} /> : <></>}
        {pageCode === 'MLS070' ? <GlobalPartsListIcon className={styles.icon} /> : <></>}
        {pageCode === 'MLS080' ? <UnitPartsListIcon className={styles.icon} /> : <></>}
        {['MLS140', 'MLS090'].includes(pageCode) ? <ContractListIcon className={styles.icon} /> : <></>}
        {pageCode === 'MLS100' ? <ContractRouteListIcon className={styles.icon} /> : <></>}
        {pageCode === 'MLS170' ? <FobSettingIcon className={styles.icon} /> : <></>}
        {['MLS110', 'MLS180', 'MLS190'].includes(pageCode) ? <PaymentTermsListIcon className={styles.icon} /> : <></>}
        {pageCode === 'MLS120' ? <ShippingRouteListIcon className={styles.icon} /> : <></>}
        {pageCode === 'MLS250' ? <CargoStatusSettingIcon className={styles.icon} /> : <></>}
        {pageCode === 'MOS020' ? <RequestListIcon className={styles.icon} /> : <></>}
        {pageCode === 'MOS021AddNewPart' ? <RequestAddPartsIcon className={styles.icon} /> : <></>}
        {pageCode === 'MOS021Modify' ? <RequestModifyPackageIcon className={styles.icon} /> : <></>}
        {pageCode === 'MOS021Remove' ? <RequestRemovePartsIcon className={styles.icon} /> : <></>}
        {['MOS030', 'CAS030', 'CAS040'].includes(pageCode) ? <RequestReceiveListIcon className={styles.icon} /> : <></>}
        {pageCode === 'MGS010' ? <MasterDownloadUploadIcon className={styles.icon} /> : <></>}
        {pageCode === 'MLS060' ? <PortListIcon className={styles.icon} /> : <></>}
        {pageCode === 'MLS260' ? <PoMonitoringListIcon className={styles.icon} /> : <></>}
        {pageCode === 'CAS010' ? <RoleListIcon className={styles.icon} /> : <></>}
        {pageCode === 'CAS020' ? <UserListIcon className={styles.icon} /> : <></>}
        {pageCode === 'CSUGS130' ? <ProjectIcon className={styles.icon} /> : <></>}
        {['CSUHS120', 'VSRPS010', 'SMGTS610'].includes(pageCode) ? <StockHistoryIcon className={styles.icon} /> : <></>}
        {['CSUHS140', 'CSUHS150', 'CSUGS120'].includes(pageCode) ? <DownloadUploadCustomerUsageIcon className={styles.icon} /> : <></>}
        {pageCode === 'SMGTS110' ? <WeeklyStockIcon className={styles.icon} /> : <></>}
        {pageCode === 'SMGTS210' ? <DailyRundownIcon className={styles.icon} /> : <></>}
        {pageCode === 'SMGTS310' ? <CustomerStockIcon className={styles.icon} /> : <></>}
        {pageCode === 'SMGTS410' ? <StockAdjustmentIcon className={styles.icon} /> : <></>}
        {['SMGTS710', 'SMGTS810', 'SMGTS710MAPA', 'SMGTS810MAPA', 'INT050', 'INT060', 'MSAUF010', 'MSAUF020SM', 'MSAUF020OC', 'MSAUF020SMOC'].includes(pageCode) ? <OrderCalculationSettingIcon className={styles.icon} /> : <></>}
        {['MSOGS010', 'MSOGS020'].includes(pageCode) ? <OrderCalculationGroupingIcon className={styles.icon} /> : <></>}
        {['OCCLS010', 'OCCLS010PNA', 'OCCLS010SMT'].includes(pageCode) ? <OrderCalculationListIcon className={styles.icon} /> : <></>}
        {['INT010', 'INT020', 'INT030', 'INT040'].includes(pageCode) ? <ManageExternalDataIcon className={styles.icon} /> : <></>}
    </div>
}


function PlaceOrderIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.53042 0 0 .53042 -10.985 -8.3692)" fill="none" stroke="currentColor" strokeWidth="1.2" data-name="组 4082">
            <path transform="translate(-4.841,-13.643)" d="m57.969 57.246a1.569 1.569 0 0 0-1.426 0.909v-0.937a1.62 1.62 0 0 0-3.177-0.442c0-1.189-0.557-1.967-1.616-1.967a1.617 1.617 0 0 0-1.6 1.355v-6.64a1.62 1.62 0 0 0-3.239 0v10.294c-1-1.895-2.141-2.682-3.154-2.682h-0.027a2.258 2.258 0 0 0-1.858 0.938c-0.282 0.622 2.021 1.645 3.652 5.675 1.766 4.357 3.2 6.549 7.522 6.549 4.735 0 6.5-3.315 6.5-5.45v-6.026a1.575 1.575 0 0 0-1.577-1.576z" strokeLinejoin="round" data-name="路径 1910" />
            <g strokeMiterlimit="10">
                <path transform="translate(-16.1,-12.959)" d="m68.893 47.384a1.37 1.37 0 0 0-1.286-0.87 1.3 1.3 0 0 0-0.519 0.1l-1.485 0.649a1.444 1.444 0 0 0-0.742 1.866 1.373 1.373 0 0 0 1.287 0.87 1.3 1.3 0 0 0 0.519-0.105l1.484-0.648a1.442 1.442 0 0 0 0.742-1.862z" data-name="路径 1911" />
                <path transform="translate(-16.297,-16.436)" d="m66.884 56.648 1.343-0.586a1.3 1.3 0 0 0 0.67-1.686 1.239 1.239 0 0 0-1.162-0.788 1.188 1.188 0 0 0-0.47 0.094l-1.342 0.587a1.3 1.3 0 0 0-0.671 1.686 1.24 1.24 0 0 0 1.163 0.787 1.174 1.174 0 0 0 0.469-0.094z" data-name="路径 1912" />
                <path transform="translate(-16.106,-9.678)" d="m64.874 42.27a1.375 1.375 0 0 0 1.287 0.869 1.307 1.307 0 0 0 0.519-0.1l1.006-0.46a1.444 1.444 0 0 0 0.743-1.865 1.375 1.375 0 0 0-1.287-0.872 1.3 1.3 0 0 0-0.519 0.1l-1.007 0.46a1.442 1.442 0 0 0-0.742 1.868z" data-name="路径 1913" />
                <line transform="translate(43.258,21.869)" x2="1.048" strokeLinecap="round" data-name="直线 902" />
                <line transform="translate(37.442,23.465)" x2="12.681" data-name="直线 903" />
                <path transform="translate(-17.519,-4.341)" d="m67.642 33.2 2.711-1.16a1.63 1.63 0 0 0 0.838-2.107 1.393 1.393 0 0 0-1.835-0.867c-0.245 0.07-1.392 0.476-1.714 0.612" data-name="路径 1914" />
                <path transform="translate(-15.644,-19.48)" d="m63.828 61.282 0.373-0.035c0.806-0.123 1.682-0.672 1.682-1.465" data-name="路径 1915" />
                <path transform="translate(-5.261)" d="m55.384 30.445v-8.368a1.67 1.67 0 0 0-1.39-1.674c-1.786-0.168-3.364-0.254-4.946-0.254s-3.167 0.085-4.97 0.256a1.667 1.667 0 0 0-1.378 1.672v17.98a1.666 1.666 0 0 0 1.391 1.672c1.1 0.106 2.175 0.177 3.24 0.218" data-name="路径 1916" />
                <line transform="translate(45.31,38.13)" x2="3.897" data-name="直线 904" />
                <line transform="translate(37.442,38.13)" x2="4.63" data-name="直线 905" />
                <path transform="translate(0,-3.073)" d="m37.442 28.189a2.714 2.714 0 0 0-3.22-1.665c-0.675 0.4 0.822 2.965-0.787 7.718a24.308 24.308 0 0 0-1.408 5.5 7.389 7.389 0 0 0 4.985 7.828" data-name="路径 1917" />
            </g>
            <path transform="translate(-9.233,-6.936)" d="m50.785 36.318 2.1 2.1 3.1-4.157" strokeLinecap="round" strokeLinejoin="round" data-name="路径 1918" />
        </g>
    </SvgIcon>
}

function ChangeOrderForecastIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.53255 0 0 .53255 -184 -184.63)" fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="1.2" data-name="组 4083">
            <path transform="translate(-5.326)" d="m384.74 360.59a14.257 14.257 0 0 0-23.482 0" data-name="路径 1919" />
            <path transform="translate(-22.536,-1.976)" d="m402.2 358.65-0.228 3.921-3.92-0.228" data-name="路径 1920" />
            <path transform="translate(-5.547,-20.592)" d="m361.73 398.45a14.257 14.257 0 0 0 23.482 0" data-name="路径 1921" />
            <path transform="translate(-5.326,-20.592)" d="m361.25 402.37 0.228-3.92 3.92 0.228" data-name="路径 1922" />
            <path transform="translate(-25.159,-8.163)" d="m406.98 371.88v2.883l-0.5-0.247-0.572 0.281-0.572-0.281-0.576 0.282-0.577-0.282-0.525 0.256v-2.892" data-name="路径 1923" />
            <rect transform="translate(374.1 363.71)" width="12.122" height="11.562" data-name="矩形 14406" />
            <path transform="translate(-3.865,-8.259)" d="m361.45 372.08v2.883l-0.5-0.247-0.572 0.281-0.572-0.281-0.576 0.282-0.577-0.282-0.525 0.256v-2.892" data-name="路径 1924" />
            <rect transform="translate(349.87 363.82)" width="12.122" height="11.562" data-name="矩形 14407" />
        </g>
    </SvgIcon>
}

function ChangeCancelRequestListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g fill="none" stroke="currentColor">
            <g transform="matrix(.57779 0 0 .57779 -264.83 -12.226)" data-name="组 4088">
                <g transform="translate(469.74 37.106)" data-name="组 4087">
                    <g transform="translate(-469.74 -37.106)" fill="none" stroke="currentColor" strokeWidth="1.2" data-name="组 4088">
                        <path transform="translate(-2.552)" d="m490.58 29.49a1.452 1.452 0 0 0-1.45-1.451h-3.732a0.632 0.632 0 0 1-0.626-0.478 3.27 3.27 0 0 0-6.435 0 0.637 0.637 0 0 1-0.634 0.48h-3.72a1.452 1.452 0 0 0-1.45 1.451v3.946h18.043z" strokeLinejoin="round" data-name="路径 1941" />
                        <path transform="translate(0,-3.921)" d="m477.84 62.21h-11.417v-27.944h1.727" strokeLinejoin="round" data-name="路径 1942" />
                        <line transform="translate(478.95 27.995)" x2=".119" strokeLinecap="round" strokeMiterlimit="10" data-name="直线 917" />
                        <g transform="translate(469.74 37.106)" strokeLinejoin="round" data-name="组 4087">
                            <path transform="translate(-474.41,-45.87)" d="m489.97 49.119a7.509 7.509 0 0 0-12.367 0" data-name="路径 1943" />
                            <path transform="translate(-481.8,-46.718)" d="m497.49 47.9-0.12 2.065-2.065-0.12" data-name="路径 1944" />
                            <path transform="translate(-474.5 -53.713)" d="m477.83 67.055a7.508 7.508 0 0 0 12.367 0" data-name="路径 1945" />
                            <path transform="translate(-474.41 -53.713)" d="m477.6 69.119 0.12-2.065 2.064 0.12" data-name="路径 1946" />
                            <rect transform="translate(12.763,4.892)" width="6.384" height="6.09" data-name="矩形 14414" />
                            <rect transform="translate(0,4.95)" width="6.384" height="6.09" data-name="矩形 14415" />
                        </g>
                        <path transform="translate(-7.5,-3.921)" d="m484.39 62.21h14.913v-27.944h-1.727" strokeLinejoin="round" data-name="路径 1949" />
                    </g>
                </g>
            </g>
        </g>
    </SvgIcon>
}

function CoMonitoringListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.53042 0 0 .53042 -126.5 -67.551)" fill="none" stroke="currentColor" strokeWidth="1.2" data-name="组 4084">
            <g strokeLinejoin="round">
                <line transform="translate(254.68 156.67)" x2="9.483" data-name="直线 906" />
                <path transform="translate(0,-8.969)" d="m255.38 158.62a1.8 1.8 0 0 0-2.286 0.813l-2.9 5.832-0.676-2.991 2.334-3.341v-4.622l-6.793 6.055a2.834 2.834 0 0 0-0.525 2.3l0.365 9.43c0.056 0.729 0.169 1.029 0.906 1.178l3.429 0.641c0.512 0.091 0.966 0.246 1.375-0.292 0.631-0.831 1.865-2.455 2.15-2.859a25.688 25.688 0 0 0 1.539-4.1c0.539-1.575 2.364-5.984 2.364-5.984a1.766 1.766 0 0 0-1.282-2.06z" data-name="路径 1925" />
                <line transform="translate(251.85 139.97)" y2="5.379" data-name="直线 907" />
                <path transform="translate(-11.891,-12.945)" d="m282.42 182.68c-2.131 0-3.671-1.053-5.683-3.884a17.187 17.187 0 0 0-3.3-3.471c-0.69-0.572-1.069-0.888-0.981-1.3a2.338 2.338 0 0 1 1.625-1.378 1.771 1.771 0 0 1 0.481-0.063 3.729 3.729 0 0 1 2.382 1.082l-1.755-7.922a1.709 1.709 0 0 1 1.295-2.04 1.644 1.644 0 0 1 0.38-0.045 1.744 1.744 0 0 1 1.666 1.338l1.053 4.754a1.672 1.672 0 0 1 1.417-0.715 1.594 1.594 0 0 1 1.435 0.882 1.785 1.785 0 0 1 1.457-0.8 1.723 1.723 0 0 1 1.67 1.34l0.014 0.068a1.719 1.719 0 0 1 1.269-0.563 1.664 1.664 0 0 1 1.632 1.31l1.06 4.785c0.422 1.907-0.507 5.305-4.8 6.308a10.227 10.227 0 0 1-2.317 0.314z" data-name="路径 1926" />
                <line transform="translate(253.18 160.77)" x2="7.522" data-name="直线 908" />
                <path transform="translate(-5.445)" d="m257.3 152.97v-19.195a0.543 0.543 0 0 1 0.542-0.542h20.369a0.543 0.543 0 0 1 0.542 0.542v23" data-name="路径 1927" />
            </g>
            <g strokeMiterlimit="10">
                <path transform="translate(-14.116,-7.271)" d="m280.53 151.75a1.424 1.424 0 1 1-1.424-1.424 1.423 1.423 0 0 1 1.424 1.424z" data-name="路径 1928" />
                <path transform="translate(-16.805,-4.102)" d="m286.85 144.3a1.424 1.424 0 1 1-1.424-1.423 1.423 1.423 0 0 1 1.424 1.423z" data-name="路径 1929" />
                <path transform="translate(-7.86,-8.325)" d="m265.82 154.22a1.424 1.424 0 1 1-1.421-1.425 1.424 1.424 0 0 1 1.421 1.425z" data-name="路径 1930" />
                <path transform="translate(-10.55,-5.155)" d="m272.14 146.78a1.424 1.424 0 1 1-1.424-1.424 1.423 1.423 0 0 1 1.424 1.424z" data-name="路径 1931" />
                <line transform="translate(257.47 142.72)" x2="1.77" y1="2.085" data-name="直线 909" />
                <line transform="translate(261.36 142.39)" x2="2.396" y2="1.366" data-name="直线 910" />
                <line transform="translate(265.9 141.32)" x2="1.851" y1="2.064" data-name="直线 911" />
            </g>
        </g>
    </SvgIcon>
}

function PoMonitoringListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.4914 0 0 .4914 -117.22 -62.052)" fill="none" stroke="currentColor" strokeWidth="1.2" data-name="组 4086">
            <path transform="translate(-9.436,-11.897)" d="m290.53 164.04c-1.465-1.154-2.2-1.728-3.662-2.882a0.854 0.854 0 0 0-1.127-0.051 128.63 128.63 0 0 0-9.415 12.664c-3.344 5.15-2.8 6.6-2.8 6.6-0.256 0.485-0.7 1.489-0.221 1.865s1.335-0.277 1.748-0.643c0 0 1.568 0.162 5.865-4.4a119.06 119.06 0 0 0 9.92-12.078 0.854 0.854 0 0 0-0.308-1.075z" strokeLinejoin="round" data-name="路径 1935" />
            <path transform="translate(-12.377,-11.495)" d="m286.39 159.91a56.217 56.217 0 0 0-5.878 7.472" strokeLinecap="round" strokeLinejoin="round" data-name="路径 1936" />
            <line transform="translate(266.89 161.87)" x2="4.597" y2="3.427" data-name="直线 912" />
            <path transform="translate(-18.673,-10.952)" d="m299.51 162.89 1.37-1.741c0.164-0.207 0.053-0.57-0.249-0.806l-2.046-1.609c-0.3-0.236-0.677-0.26-0.841-0.052l-1.37 1.74" data-name="路径 1937" />
            <g strokeLinejoin="round">
                <path transform="translate(-2.427)" d="m274.12 135.73a1.5 1.5 0 0 0-1.5-1.5h-3.86a0.655 0.655 0 0 1-0.648-0.5 3.385 3.385 0 0 0-6.661 0 0.66 0.66 0 0 1-0.656 0.5h-3.851a1.5 1.5 0 0 0-1.5 1.5v4.085h18.676z" data-name="路径 1938" />
                <rect transform="translate(252.9 143.58)" width="2.859" height="2.859" data-name="矩形 14411" />
                <line transform="translate(257.98 145.01)" x2="13.714" data-name="直线 913" />
                <rect transform="translate(252.9 150.69)" width="2.859" height="2.858" data-name="矩形 14412" />
                <line transform="translate(257.98 152.12)" x2="9.76" data-name="直线 914" />
                <rect transform="translate(252.9 157.8)" width="2.859" height="2.857" data-name="矩形 14413" />
                <line transform="translate(257.98 159.23)" x2="7.205" data-name="直线 915" />
                <path transform="translate(0,-3.729)" d="m261.15 169.27h-11.819v-28.926h1.788" data-name="路径 1939" />
                <path transform="translate(-16.054,-3.729)" d="m289.77 140.34h1.73v9.831" data-name="路径 1940" />
            </g>
            <line transform="translate(262.29 134.18)" x2=".124" strokeLinecap="round" strokeMiterlimit="10" data-name="直线 916" />
        </g>
    </SvgIcon>
}

function SoMonitoringListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.58691 0 0 .58691 -203.21 -205.94)" fill="none" stroke="currentColor" strokeWidth="1.2" data-name="组 4089">
            <line transform="translate(350.59 376.26)" x2="14.899" strokeLinejoin="round" data-name="直线 918" />
            <path transform="translate(0,-1.049)" d="m361.71 382.71v-2.316h-9.892a1.23 1.23 0 0 1-1.228-1.229v-17.676h17.929" strokeLinejoin="round" data-name="路径 1950" />
            <g strokeMiterlimit="10">
                <path transform="translate(-17.487)" d="m386.58 364.38a5.056 5.056 0 1 0 5.054-5.055 5.054 5.054 0 0 0-5.054 5.055z" data-name="路径 1951" />
                <path transform="translate(-13.45,-17.386)" d="m378.27 401.32v-4.4a1.82 1.82 0 0 1 1.82-1.817h15.011a1.82 1.82 0 0 1 1.819 1.817v4.4" data-name="路径 1952" />
                <path transform="translate(-13.993,-11.493)" d="m379.38 389.66v-0.838a5.843 5.843 0 0 1 5.843-5.843h5.818a5.843 5.843 0 0 1 5.843 5.843v0.838" data-name="路径 1953" />
            </g>
            <g strokeLinejoin="round">
                <line transform="translate(357.76 381.66)" x2="7.062" strokeLinecap="round" data-name="直线 919" />
                <path transform="translate(-2.541,-10.123)" d="m358.58 381.54a1.377 1.377 0 1 1-1.378-1.378 1.378 1.378 0 0 1 1.378 1.378z" data-name="路径 1954" />
                <path transform="translate(-5.614,-5.756)" d="m364.9 372.55a1.377 1.377 0 1 1-1.378-1.378 1.377 1.377 0 0 1 1.378 1.378z" data-name="路径 1955" />
                <path transform="translate(-10.198,-8.648)" d="m374.33 378.5a1.377 1.377 0 1 1-1.378-1.378 1.378 1.378 0 0 1 1.378 1.378z" data-name="路径 1956" />
                <path transform="translate(-12.987,-4.394)" d="m380.07 369.74a1.377 1.377 0 1 1-1.378-1.378 1.377 1.377 0 0 1 1.378 1.378z" data-name="路径 1957" />
                <line transform="translate(363.51 366.5)" x2="1.44" y1="2.196" data-name="直线 920" />
                <line transform="translate(359.06 367.54)" x2="2.529" y2="1.579" data-name="直线 921" />
                <line transform="translate(355.43 367.92)" x2="1.692" y1="2.367" data-name="直线 922" />
            </g>
        </g>
    </SvgIcon>
}

function InboundMonitoringListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.57534 0 0 .57534 -433.93 -308.88)" fill="none" stroke="currentColor" data-name="组 4094">
            <g strokeMiterlimit="10" strokeWidth="1.2">
                <line transform="translate(780.12 563.58)" x2="7.503" data-name="直线 927" />
                <line transform="translate(780.12 566.45)" x2="7.503" data-name="直线 928" />
                <path transform="translate(-14.195,-67.538)" d="m777.59 633.99" data-name="路径 1965" />
                <path transform="translate(-14.195)" d="m777.59 554.21v-7.489a1.066 1.066 0 0 1 1.066-1.066h25.29a1.058 1.058 0 0 1 1.058 1.058v19.744" data-name="路径 1966" />
                <path transform="translate(-14.195,-10.767)" d="m805.01 559.74v19.745a1.057 1.057 0 0 1-1.057 1.057h-25.3a1.058 1.058 0 0 1-1.058-1.059v-2.257" data-name="路径 1967" />
                <path transform="translate(-45.392)" d="m826.59 545.65h-8.2v4.367l1.2 1.054 0.965-0.965 0.965 0.965 0.965-0.965 0.965 0.965 0.965-0.965 0.965 0.965 1.2-1.054z" data-name="路径 1968" />
            </g>
            <g transform="translate(759.03 554.6)" data-name="组 4093">
                <g data-name="组 4092">
                    <g data-name="组 4091">
                        <path transform="translate(-759.03 -583.64)" d="m757.25 590.46h7.8l-0.861 3.213 6.963-5.014-4.276-5.014-0.861 3.213h-6.988" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" data-name="路径 1969" />
                    </g>
                </g>
            </g>
        </g>
    </SvgIcon>
}

function DownloadDeliveryPlanIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.50707 0 0 .50841 -251.88 -155.15)" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="1.2" data-name="组 4096">
            <path transform="translate(-51.269)" d="m571.22 317.35h-6.073v3.236l0.891 0.781 0.715-0.715 0.715 0.715 0.715-0.715 0.715 0.715 0.715-0.715 0.715 0.715 0.891-0.781z" data-name="路径 1970" />
            <line transform="translate(519.96 330.89)" x2="5.016" data-name="直线 929" />
            <line transform="translate(519.96 332.68)" x2="5.016" data-name="直线 930" />
            <g transform="translate(500.28 324.32)" data-name="组 4095">
                <line transform="translate(2.186)" x2="6.661" data-name="直线 931" />
                <line transform="translate(2.186,4.974)" x2="6.661" data-name="直线 932" />
                <line transform="translate(0,2.5)" x2="6.661" data-name="直线 933" />
            </g>
            <path transform="translate(-109.76 -27.274)" d="m643.69 351.86h-3.99a0.537 0.537 0 0 0-0.537 0.537v2.585a0.537 0.537 0 0 0 0.537 0.537h5.58a0.537 0.537 0 0 0 0.457-0.818l-1.59-2.584a0.537 0.537 0 0 0-0.457-0.257z" data-name="路径 1971" />
            <line transform="translate(516.68 336.27)" x1="12.897" data-name="直线 934" />
            <path transform="translate(-101.18 -21.203)" d="m638.28 357.47h1.574a0.441 0.441 0 0 0 0.441-0.441v-7.242a0.534 0.534 0 0 0-0.093-0.3l-3.442-5.039a0.619 0.619 0 0 0-0.511-0.27h-7.952" data-name="路径 1972" />
            <path transform="translate(-19.511,-54.511)" d="m524.97 386.32v4.457h3.7" data-name="路径 1973" />
            <path transform="translate(-19.511)" d="m546.64 336.27v-18.481a0.438 0.438 0 0 0-0.438-0.438h-20.79a0.438 0.438 0 0 0-0.438 0.438v4.018" data-name="路径 1974" />
            <path transform="translate(-41.073,-65.397)" d="m555.74 401.84a1.744 1.744 0 1 1-1.744-1.744 1.744 1.744 0 0 1 1.744 1.744z" data-name="路径 1975" />
            <path transform="translate(-33.45,-57.775)" d="m550.14 394.22a3.766 3.766 0 1 1-3.766-3.766 3.766 3.766 0 0 1 3.766 3.766z" data-name="路径 1976" />
            <path transform="translate(-118.04 -65.397)" d="m653.12 401.84a1.744 1.744 0 1 1-1.744-1.744 1.744 1.744 0 0 1 1.744 1.744z" data-name="路径 1977" />
            <path transform="translate(-110.42 -57.775)" d="m647.52 394.22a3.766 3.766 0 1 1-3.766-3.766 3.766 3.766 0 0 1 3.766 3.766z" data-name="路径 1978" />
            <line transform="translate(529.77 329.35)" x2="1.354" data-name="直线 935" />
            <line transform="translate(535.28 330.89)" x2="3.845" data-name="直线 936" />
        </g>
    </SvgIcon>
}

function DownloadOutboundInstructionIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.59549 0 0 .59549 -578.44 -319.31)" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="1.2" data-name="组 4107">
            <path transform="translate(-53.076,-81.66)" d="m1046.5 650.82" data-name="路径 1993" />
            <path transform="translate(-65.445,-6.845)" d="m1062.8 551.96h5.52v6.343" data-name="路径 1994" />
            <line transform="translate(983.5 545.11)" x2="12.244" data-name="直线 948" />
            <path transform="translate(0,-6.845)" d="m987.67 576h-11.326v-24.043h5.52" data-name="路径 1995" />
            <g transform="translate(981.86 542.91)" data-name="组 4105">
                <path transform="translate(-1042.2 -542.91)" d="m1056.9 547.31a0.817 0.817 0 0 1-0.817-0.817v-2.766a0.817 0.817 0 0 1 0.817-0.817 0.817 0.817 0 0 1 0.817 0.817v2.766a0.817 0.817 0 0 1-0.817 0.817z" data-name="路径 1996" />
                <path transform="translate(-999.04 -542.91)" d="m999.85 547.31a0.817 0.817 0 0 1-0.817-0.817v-2.766a0.817 0.817 0 0 1 0.817-0.817 0.817 0.817 0 0 1 0.817 0.817v2.766a0.817 0.817 0 0 1-0.817 0.817z" data-name="路径 1997" />
            </g>
            <line transform="translate(976.34 549.74)" x2="26.552" data-name="直线 949" />
            <rect transform="translate(984.4 553.49)" width="2.697" height="2.697" data-name="矩形 14416" />
            <rect transform="translate(979.92 558.1)" width="2.697" height="2.697" data-name="矩形 14417" />
            <rect transform="translate(984.4 558.1)" width="2.697" height="2.697" data-name="矩形 14418" />
            <rect transform="translate(984.4 562.7)" width="2.697" height="2.697" data-name="矩形 14419" />
            <rect transform="translate(979.92 562.7)" width="2.697" height="2.697" data-name="矩形 14420" />
            <g transform="translate(990.84 553.42)" data-name="组 4106">
                <line transform="translate(10.909,11.698)" x2="4.894" data-name="直线 950" />
                <line transform="translate(10.909,13.57)" x2="4.894" data-name="直线 951" />
                <path transform="translate(-1035.9 -628.35)" d="m1035.9 641.92" data-name="路径 1998" />
                <path transform="translate(-1035.9 -586.13)" d="m1053.8 588.29v12.88a0.69 0.69 0 0 1-0.69 0.69h-16.5a0.69 0.69 0 0 1-0.69-0.69v-14.348a0.7 0.7 0 0 1 0.7-0.7h16.5a0.69 0.69 0 0 1 0.69 0.69v12.888" data-name="路径 1999" />
                <path transform="translate(-1055.4 -586.13)" d="m1067.1 586.13h-5.346v2.848l0.784 0.688 0.63-0.63 0.63 0.63 0.63-0.63 0.63 0.63 0.63-0.63 0.63 0.63 0.784-0.688z" data-name="路径 2000" />
            </g>
        </g>
    </SvgIcon>
}

function OutboundMonitoringListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.57534 0 0 .57534 -433.93 -308.88)" fill="none" stroke="currentColor" data-name="组 4094">
            <g strokeMiterlimit="10" strokeWidth="1.2">
                <line transform="translate(780.12 563.58)" x2="7.503" data-name="直线 927" />
                <line transform="translate(780.12 566.45)" x2="7.503" data-name="直线 928" />
                <path transform="translate(-14.195,-67.538)" d="m777.59 633.99" data-name="路径 1965" />
                <path transform="translate(-14.195)" d="m777.59 554.21v-7.489a1.066 1.066 0 0 1 1.066-1.066h25.29a1.058 1.058 0 0 1 1.058 1.058v19.744" data-name="路径 1966" />
                <path transform="translate(-14.195,-10.767)" d="m805.01 559.74v19.745a1.057 1.057 0 0 1-1.057 1.057h-25.3a1.058 1.058 0 0 1-1.058-1.059v-2.257" data-name="路径 1967" />
                <path transform="translate(-45.392)" d="m826.59 545.65h-8.2v4.367l1.2 1.054 0.965-0.965 0.965 0.965 0.965-0.965 0.965 0.965 0.965-0.965 0.965 0.965 1.2-1.054z" data-name="路径 1968" />
            </g>
            <g transform="translate(759.03 554.6)" data-name="组 4093">
                <g data-name="组 4092">
                    <g data-name="组 4091">
                        <path transform="translate(-759.03 -583.64)" d="m757.25 590.46h7.8l-0.861 3.213 6.963-5.014-4.276-5.014-0.861 3.213h-6.988" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" data-name="路径 1969" />
                    </g>
                </g>
            </g>
        </g>
    </SvgIcon>
}

function CargoTrackingDetailIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.19617 0 0 .19617 -5.0742 -3.6738)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
            <line x1="137.35" x2="133" y1="104.27" y2="104.27" />
            <line x1="122.12" x2="122.12" y1="101.87" y2="104.86" />
            <polygon points="99.09 87.51 100.67 85.35 100.67 79.62 88.04 79.62 88.04 85.08 89.62 87.51 91.2 85.35 92.69 87.51 94.36 85.35 95.65 87.51 97.51 85.35" />
            <line x1="102.25" x2="112.23" y1="105.65" y2="105.65" />
            <line x1="102.28" x2="112.26" y1="108.62" y2="108.62" />
            <polygon points="119.6 94.61 128.49 94.61 133.02 101.27 119.6 101.27" />
            <polyline points="91.06 114.05 115.66 114.05 115.66 79.33 73.84 79.33 73.84 114.05 79.24 114.05" />
            <path d="m91.06 114.05c-0.01 3.25-2.65 5.89-5.91 5.89s-5.9-2.63-5.91-5.89" />
            <path d="m91.06 114.05v-0.03c0-3.26-2.65-5.91-5.91-5.91s-5.91 2.65-5.91 5.91v0.03" />
            <path d="m88.18 114.05c-0.01 1.67-1.37 3.01-3.04 3.01s-3.02-1.35-3.04-3.01" />
            <path d="m88.18 114.05v-0.03c0-1.68-1.36-3.04-3.04-3.04s-3.04 1.36-3.04 3.04v0.03" />
            <polyline points="131.84 114.05 138.54 114.05 138.54 101.71 130.39 89.69 115.66 89.69 115.66 114.05 120.04 114.05" />
            <path d="m120.04 114.05v0.13c0 3.26 2.65 5.91 5.91 5.91s5.91-2.65 5.91-5.91v-0.13" />
            <path d="m120.04 114.05c0.07-3.2 2.68-5.78 5.9-5.78s5.84 2.58 5.9 5.78" />
            <path d="m122.91 114.05c0.07-1.62 1.39-2.91 3.03-2.91s2.96 1.29 3.03 2.91" />
            <path d="m122.91 114.05s-0.01 0.08-0.01 0.13c0 1.68 1.36 3.04 3.04 3.04s3.04-1.36 3.04-3.04c0-0.04-0.01-0.08-0.01-0.13" />
            <polyline points="107.28 114.05 107.28 123.5 38.59 123.5 38.59 36.08 50.1 36.08 95.21 36.08 107.28 36.08 107.28 79.33" />
            <line x1="51.12" x2="96.2" y1="54.15" y2="54.15" />
            <line x1="50.86" x2="95.94" y1="60.82" y2="60.82" />
            <line x1="50.07" x2="95.15" y1="76.52" y2="76.52" />
            <line x1="49.81" x2="73.2" y1="83.2" y2="83.2" />
            <line x1="49.94" x2="73.2" y1="98.89" y2="98.89" />
            <line x1="49.81" x2="73.07" y1="105.57" y2="105.57" />
        </g>
    </SvgIcon>
}

function CargoTrackingIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.1971 0 0 .19711 -3.3696 -2.7053)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
            <polygon points="48 34.68 49.63 32.79 51.02 34.68 52.4 32.79 52.4 27.75 41.32 27.75 41.32 32.55 42.7 34.68 44.09 32.79 45.4 34.68 46.86 32.79" />
            <line x1="53.79" x2="62.55" y1="50.61" y2="50.61" />
            <line x1="53.82" x2="62.58" y1="53.22" y2="53.22" />
            <polygon points="80.81 46.76 69.03 46.76 69.03 40.91 76.83 40.91" />
            <line x1="71.24" x2="71.24" y1="47.29" y2="49.92" />
            <polyline points="43.96 57.99 65.56 57.99 65.56 27.49 28.85 27.49 28.85 57.99 33.59 57.99" />
            <path d="m43.96 57.99c-0.01 2.86-2.33 5.17-5.19 5.17s-5.18-2.31-5.19-5.17" />
            <path d="m43.96 57.99v-0.02c0-2.87-2.32-5.19-5.19-5.19s-5.19 2.32-5.19 5.19v0.02" />
            <path d="m41.44 57.99c-0.01 1.46-1.2 2.65-2.67 2.65s-2.65-1.18-2.67-2.65" />
            <path d="m41.44 57.99v-0.02c0-1.47-1.19-2.67-2.67-2.67s-2.67 1.19-2.67 2.67v0.02" />
            <polyline points="79.78 57.99 85.65 57.99 85.65 47.15 78.5 36.59 65.56 36.59 65.56 57.99 69.41 57.99" />
            <path d="m69.41 57.99v0.11c0 2.87 2.32 5.19 5.19 5.19s5.19-2.32 5.19-5.19v-0.11" />
            <path d="m69.41 57.99c0.06-2.81 2.36-5.08 5.18-5.08s5.12 2.26 5.18 5.08" />
            <path d="m71.94 57.99c0.06-1.42 1.22-2.56 2.66-2.56s2.6 1.14 2.66 2.56" />
            <path d="m71.94 57.99s-0.01 0.07-0.01 0.11c0 1.47 1.19 2.67 2.67 2.67s2.67-1.19 2.67-2.67c0-0.04 0-0.07-0.01-0.11" />
            <path d="m45.16 86.43h1.03" />
            <path d="m110.79 86.43c9.63 0 17.43-7.81 17.43-17.43 0-9.63-7.81-17.43-17.43-17.43h-25.12" />
            <path d="m46.18 86.43c-9.63 0-17.43 7.81-17.43 17.43 0 9.63 7.81 17.43 17.43 17.43h65.64" />
            <line x1="110.79" x2="46.18" y1="86.43" y2="86.43" />
            <circle cx="111.18" cy="106.77" r="3.6" />
            <path d="m119.53 106.77c0-4.58-3.71-8.29-8.29-8.29s-8.29 3.71-8.29 8.29c0 1.92 0.66 3.67 1.75 5.08l5.54 9.09c0.46 0.75 1.55 0.75 2.01 0l5.54-9.09c1.09-1.4 1.75-3.16 1.75-5.08z" />
            <line x1="84.5" x2="82" y1="50" y2="50" />
        </g>
    </SvgIcon>
}

function ShippingDetailListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.19144 0 0 .19144 -3.4992 -2.3581)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
            <polyline points="87.39 36.35 99.46 36.35 99.46 57.06" />
            <polyline points="99.46 114.33 99.46 123.77 30.77 123.77 30.77 36.35 42.28 36.35" />
            <path d="m85.27 32.67h-16.9c0.44-0.66 0.7-1.45 0.7-2.3 0-2.29-1.85-4.14-4.14-4.14s-4.14 1.85-4.14 4.14c0 0.85 0.26 1.64 0.7 2.3h-16.9c-0.71 0-1.28 0.57-1.28 1.28v8.48c0 0.71 0.57 1.28 1.28 1.28h40.69c0.71 0 1.28-0.57 1.28-1.28v-8.48c0-0.71-0.57-1.28-1.28-1.28z" />
            <circle cx="64.92" cy="30.37" r=".92" />
            <line x1="43.3" x2="88.38" y1="54.42" y2="54.42" />
            <line x1="43.04" x2="82.73" y1="61.1" y2="61.1" />
            <line x1="42.25" x2="82.73" y1="76.8" y2="76.8" />
            <line x1="41.99" x2="81.73" y1="83.47" y2="83.56" />
            <line x1="42.12" x2="65.38" y1="99.17" y2="99.17" />
            <line x1="41.99" x2="65.25" y1="105.85" y2="105.85" />
            <polygon points="85.72 94.03 86.99 92.29 86.99 87.67 76.81 87.67 76.81 92.07 78.08 94.03 79.35 92.29 80.56 94.03 81.9 92.29 82.94 94.03 84.45 92.29" />
            <line x1="88.26" x2="96.32" y1="108.67" y2="108.67" />
            <line x1="88.29" x2="96.34" y1="111.07" y2="111.07" />
            <rect x="65.35" y="87.43" width="33.74" height="28.02" />
            <polygon points="102.95 64.32 104.22 62.59 104.22 57.96 94.04 57.96 94.04 62.36 95.31 64.32 96.58 62.59 97.79 64.32 99.13 62.59 100.17 64.32 101.67 62.59" />
            <line x1="105.49" x2="113.55" y1="78.96" y2="78.96" />
            <line x1="105.52" x2="113.57" y1="81.36" y2="81.36" />
            <rect x="82.58" y="57.72" width="33.74" height="28.02" />
            <polygon points="119.86 93.93 121.14 92.19 121.14 87.56 110.95 87.56 110.95 91.97 112.23 93.93 113.5 92.19 114.7 93.93 116.05 92.19 117.09 93.93 118.59 92.19" />
            <line x1="122.41" x2="130.46" y1="108.57" y2="108.57" />
            <line x1="122.44" x2="130.49" y1="110.97" y2="110.97" />
            <rect x="99.5" y="87.33" width="33.74" height="28.02" />
        </g>
    </SvgIcon>
}

function CustomerDiListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.54796 0 0 .54796 -189.92 -10.856)" fill="none" stroke="currentColor" strokeWidth="1.2" data-name="组 4090">
            <path transform="translate(-11.623)" d="m390.64 30.256a6.187 6.187 0 1 0-6.187 6.187 6.188 6.188 0 0 0 6.187-6.187z" strokeLinejoin="round" data-name="路径 1958" />
            <g strokeMiterlimit="10">
                <line transform="translate(355.34 53.347)" x2="4.935" data-name="直线 923" />
                <line transform="translate(378.53 53.347)" x2="6.766" data-name="直线 924" />
                <path transform="translate(-4.332,-25.414)" d="m382.86 80.87h2.192a0.4 0.4 0 0 1 0.377 0.62l-2.312 3.01c-0.261 0.344-0.506 0.262-0.811 0.262h-17.15c-0.3 0-0.46 0.059-0.63-0.233l-2.491-3.04a0.4 0.4 0 0 1 0.376-0.62h2.19" data-name="路径 1959" />
                <path transform="translate(-6.459,-18.155)" d="m366.73 77.38v-12.18a0.554 0.554 0 0 1 0.553-0.553h17.15a0.554 0.554 0 0 1 0.553 0.553v12.261" data-name="路径 1960" />
            </g>
            <g strokeLinejoin="round">
                <path transform="translate(-3.779,-3.565)" d="m360.74 32.967 6.827-0.93 0.637 4.676-5.634 0.772" data-name="路径 1961" />
                <line transform="translate(358.64 30.404)" x2="5.415" y1=".737" data-name="直线 925" />
                <path transform="translate(0,-3.999)" d="m357.58 39.562a3.578 3.578 0 0 0 1.3-3.783 3.681 3.681 0 0 0-3.775-2.743 3.943 3.943 0 0 0-2.037 0.806c-1.033 0.873-0.907 1.826-0.357 3.761 0.355 1.244 0.669 2.448 1.406 2.9 0.913 0.561 2.27 0.043 3.463-0.941z" data-name="路径 1962" />
                <path transform="translate(-5.185,-8.157)" d="m385.57 61.5 1.325-6.695c0.6-5.313-3.2-7.818-9.3-8.168-5.437-0.312-10.748-0.488-13.713-4.341" data-name="路径 1963" />
                <path transform="translate(-1.865,-10.253)" d="m356.46 46.986a15.674 15.674 0 0 0 10.46 7.014l-0.376 2.746" data-name="路径 1964" />
            </g>
            <line transform="translate(369.4 52.876)" y2=".044" strokeLinecap="round" strokeMiterlimit="10" data-name="直线 926" />
        </g>
    </SvgIcon>
}

function CustomerInvoiceListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.63359 0 0 .63359 -280.65 -223.81)" fill="none" stroke="currentColor" data-name="组 4108">
            <path d="m474.41 357h-21.125a5.281 5.281 0 0 0-5.281 5.281v24.425a0.66 0.66 0 0 0 0.66 0.66h2.013a1.974 1.974 0 0 1 3.895 0h3.367a1.974 1.974 0 0 1 3.895 0h3.37a1.974 1.974 0 0 1 3.895 0h2.005a0.66 0.66 0 0 0 0.66-0.66v-27.065a2.641 2.641 0 0 1 2.646-2.641 2.641 2.641 0 0 1 2.641 2.641v1.98a0.66 0.66 0 0 1-0.66 0.66h-3.961" strokeMiterlimit="10" strokeWidth="1.2" data-name="路径 2001" />
            <path transform="translate(-6.967,-2.549)" d="m472.7 372.19a1.065 1.065 0 0 0-0.275-0.77 2.487 2.487 0 0 0-0.93-0.527 11.189 11.189 0 0 1-1.131-0.481 3.6 3.6 0 0 1-0.816-0.549 2.207 2.207 0 0 1-0.529-0.729 2.4 2.4 0 0 1-0.19-1 2.191 2.191 0 0 1 0.65-1.635 2.768 2.768 0 0 1 1.728-0.743v-1.26h0.963v1.281a2.526 2.526 0 0 1 1.668 0.87 2.83 2.83 0 0 1 0.6 1.874h-1.738a1.6 1.6 0 0 0-0.3-1.06 0.992 0.992 0 0 0-0.8-0.351 0.945 0.945 0 0 0-1.035 1.032 0.984 0.984 0 0 0 0.265 0.72 2.926 2.926 0 0 0 0.984 0.555 11.094 11.094 0 0 1 1.183 0.534 3.38 3.38 0 0 1 0.782 0.567 2.2 2.2 0 0 1 0.488 0.72 2.432 2.432 0 0 1 0.168 0.941 2.179 2.179 0 0 1-0.638 1.629 2.823 2.823 0 0 1-1.757 0.737v1.174h-0.957v-1.168a2.92 2.92 0 0 1-1.911-0.858 2.734 2.734 0 0 1-0.677-1.938h1.74a1.549 1.549 0 0 0 0.34 1.077 1.252 1.252 0 0 0 0.978 0.374 1.208 1.208 0 0 0 0.837-0.275 0.941 0.941 0 0 0 0.31-0.741z" fill="#fff" data-name="路径 2002" />
            <g strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2">
                <line transform="translate(453,369)" x2="4" data-name="直线 952" />
                <line transform="translate(453,374)" x2="5" data-name="直线 953" />
                <line transform="translate(453,379)" x2="10" data-name="直线 954" />
            </g>
        </g>
    </SvgIcon>
}

function BuyerInvoiceListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.66197 0 0 .66197 -231.77 -233.93)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" data-name="组 4074">
            <path d="m381.49 358.89a1.892 1.892 0 0 0-1.89-1.892h-22.708a1.892 1.892 0 0 0-1.892 1.892v27.12l1.892-1.892 1.892 1.892 1.892-1.892 1.892 1.892 1.892-1.892 1.892 1.892 1.892-1.892 1.892 1.892 1.892-1.892 1.892 1.892 1.892-1.892 1.892 1.892 1.892-1.892 1.892 1.892z" data-name="路径 1884" />
            <path transform="translate(-2.216,-4.432)" d="m364.78 369h-2.523a1.2615 1.2615 0 1 0 0 2.523h1.261a1.2615 1.2615 0 1 1 0 2.523h-2.522" data-name="路径 1885" />
            <line transform="translate(361,363)" y2="2" data-name="直线 871" />
            <line transform="translate(361,370)" y2="1" data-name="直线 872" />
            <line transform="translate(366,367)" x2="5" data-name="直线 873" />
            <line transform="translate(366,371)" x2="5" data-name="直线 874" />
            <line transform="translate(375,367)" x2="3" data-name="直线 875" />
            <line transform="translate(366,363)" x2="5" data-name="直线 876" />
            <line transform="translate(375,363)" x2="3" data-name="直线 877" />
            <line transform="translate(375,371)" x2="3" data-name="直线 878" />
            <line transform="translate(359,376)" x2="19" data-name="直线 879" />
            <line transform="translate(375,380)" x2="3" data-name="直线 880" />
            <line transform="translate(366,380)" x2="5" data-name="直线 881" />
        </g>
    </SvgIcon>
}

function SellerInvoiceListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.63029 0 0 .63029 -155.82 -221.21)" fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="1.2" data-name="组 4073">
            <g strokeLinecap="round">
                <path transform="translate(-21.739,-3.478)" d="m301 381.96h1.7a0.565 0.565 0 0 0 0.565-0.565v-15.766a0.626 0.626 0 0 0-0.626-0.626h-1.639" data-name="路径 1877" />
                <path transform="translate(-5.217,-3.478)" d="m274.3 365h-11.3v16.957h13.565" data-name="路径 1878" />
                <path transform="translate(-8.696,-6.957)" d="m274.39 373h-2.261a1.1305 1.1305 0 0 0 0 2.261h1.13a1.1305 1.1305 0 1 1 0 2.261h-2.26" data-name="路径 1879" />
                <line transform="translate(264,365)" y2="1" data-name="直线 865" />
                <line transform="translate(264,371)" y2="1" data-name="直线 866" />
                <line transform="translate(261,375)" x1="6" data-name="直线 867" />
                <line transform="translate(269,375)" x1="2" data-name="直线 868" />
            </g>
            <path transform="translate(0,-13.913)" d="m257.78 392.39a3.393 3.393 0 1 0-3.391 3.391h12.435a0.565 0.565 0 0 0 0.565-0.565v-2.826" data-name="路径 1880" />
            <path transform="translate(0 -.87)" d="m257.78 362.39a3.3915 3.3915 0 1 0-6.783 0v16.957" data-name="路径 1881" />
            <path transform="translate(-17.391)" d="m292.7 357a1.7 1.7 0 0 1 1.7 1.7v19.783a6.242 6.242 0 0 1-1.7 3.957 6.242 6.242 0 0 1-1.7-3.957v-19.783a1.7 1.7 0 0 1 1.7-1.7z" data-name="路径 1882" />
            <path transform="translate(-15.652,-2.609)" d="m292.65 363h-4.522a1.13 1.13 0 0 0-1.13 1.13v5.264" strokeLinecap="round" data-name="路径 1883" />
            <line transform="translate(274,377)" x2="3" data-name="直线 869" />
            <line transform="translate(275,382)" y1="1" strokeLinecap="round" data-name="直线 870" />
        </g>
    </SvgIcon>
}

function AccountsReceivableIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.20307 0 0 .20307 -4.3043 -3.0264)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
            <line strokeWidth="5" x1="36.5" x2="43.01" y1="47.78" y2="47.78" />
            <line strokeWidth="5" x1="36.5" x2="43.01" y1="99.58" y2="99.58" />
            <line strokeWidth="5" x1="36.5" x2="43.01" y1="65.05" y2="65.05" />
            <line strokeWidth="5" x1="36.5" x2="43.01" y1="82.31" y2="82.31" />
            <rect x="53.12" y="45.1" width="11.17" height="11.17" />
            <line x1="70.1" x2="98.07" y1="47.33" y2="47.33" />
            <line x1="70.1" x2="98.07" y1="53.96" y2="53.96" />
            <rect x="53.12" y="67.74" width="11.17" height="11.17" />
            <line x1="70.52" x2="98.49" y1="69.97" y2="69.97" />
            <line x1="70.52" x2="93.07" y1="76.6" y2="76.6" />
            <rect x="53.12" y="90.38" width="11.17" height="11.17" />
            <line x1="70.52" x2="89.49" y1="92.62" y2="92.62" />
            <line x1="70.52" x2="92.17" y1="99.24" y2="99.24" />
            <path d="m108.26 68.46v-32.08c0-3.08-2.5-5.58-5.58-5.58h-57.66c-3.08 0-5.58 2.5-5.58 5.58v75.24c0 3.08 2.5 5.58 5.58 5.58h57.66c3.08 0 5.58-2.5 5.58-5.58v-1.4" />
            <path d="m108.26 68.46c0.73-0.08 1.48-0.12 2.23-0.12 11.6 0 21 9.4 21 21s-9.4 21-21 21c-0.76 0-1.5-0.04-2.23-0.12" />
            <path d="m108.26 68.46c-10.55 1.12-18.77 10.04-18.77 20.88s8.22 19.77 18.77 20.88" />
            <path strokeWidth="1" fill="currentColor" d="m119.05 88.43v5.61c0 0.44-0.16 0.82-0.49 1.14s-0.72 0.48-1.17 0.48h-4.93v3.95c0 0.45-0.16 0.83-0.48 1.15s-0.7 0.47-1.14 0.47c-0.46 0-0.85-0.16-1.17-0.47s-0.48-0.7-0.48-1.15v-3.99h-4.89c-0.44 0-0.82-0.16-1.14-0.47s-0.48-0.7-0.48-1.15 0.16-0.83 0.47-1.15 0.7-0.47 1.15-0.47h11.44v-2.33h-11.4c-0.45 0-0.83-0.15-1.15-0.46s-0.47-0.69-0.47-1.16v-5.59c0-0.45 0.16-0.83 0.47-1.15s0.7-0.47 1.15-0.47h4.85v-3.95c0-0.44 0.15-0.82 0.45-1.14s0.71-0.48 1.22-0.48c0.44 0 0.81 0.16 1.13 0.47s0.47 0.7 0.47 1.15v3.95h4.97c0.45 0 0.84 0.16 1.17 0.48s0.49 0.7 0.49 1.14-0.16 0.84-0.49 1.17-0.72 0.49-1.17 0.49h-11.44v2.31h11.4c0.45 0 0.84 0.16 1.17 0.48s0.49 0.7 0.49 1.14z" />
        </g>
    </SvgIcon>
}

function PartsInventoryListByPartsIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.63264 0 0 .63264 -179.4 -129.81)" fill="none" stroke="currentColor" data-name="组 4078">
            <g strokeMiterlimit="10" strokeWidth="1.2">
                <path transform="translate(0,-1.699)" d="m313.76 237.1v2.641a1.32 1.32 0 0 1-1.32 1.32h-21.125a1.32 1.32 0 0 1-1.32-1.32v-24.421a1.32 1.32 0 0 1 1.32-1.32h2.641" data-name="路径 1894" />
                <path transform="translate(-10.196,-1.699)" d="m320 214h2.641a1.32 1.32 0 0 1 1.32 1.32v3.961" data-name="路径 1895" />
                <path transform="translate(-1.359,-3.059)" d="m312.48 238.46v1.32h-18.484v-21.784h1.32" data-name="路径 1896" />
                <path transform="translate(-10.196,-3.059)" d="m320 218h1.32v2.641" data-name="路径 1897" />
                <path transform="translate(-2.719)" d="m309.22 211.64h-1.98a2.641 2.641 0 1 0-5.281 0h-1.98a1.98 1.98 0 0 0-1.98 1.98v1.98h13.2v-1.98a1.98 1.98 0 0 0-1.979-1.98z" data-name="路径 1898" />
                <line transform="translate(302,211)" y2="1" data-name="直线 891" />
            </g>
            <g transform="translate(150.41 72.38)" strokeLinejoin="round" data-name="组 75">
                <path d="m169.21 154.89v-1.568a0.353 0.353 0 0 0-0.353-0.353h-0.9a0.356 0.356 0 0 1-0.343-0.273 5.64 5.64 0 0 0-0.616-1.486 0.354 0.354 0 0 1 0.048-0.435l0.576-0.576a0.383 0.383 0 0 0 0.065-0.564l-1.109-1.109a0.353 0.353 0 0 0-0.5 0l-0.64 0.639a0.356 0.356 0 0 1-0.436 0.049 5.649 5.649 0 0 0-1.486-0.617 0.354 0.354 0 0 1-0.273-0.341v-0.813c0-0.287-0.158-0.444-0.353-0.444h-1.568a0.353 0.353 0 0 0-0.353 0.353v0.9a0.356 0.356 0 0 1-0.273 0.343 5.645 5.645 0 0 0-1.486 0.617 0.354 0.354 0 0 1-0.435-0.048l-0.576-0.576a0.382 0.382 0 0 0-0.564-0.065l-1.109 1.109a0.353 0.353 0 0 0 0 0.5l0.64 0.64a0.356 0.356 0 0 1 0.049 0.436 5.649 5.649 0 0 0-0.617 1.486 0.354 0.354 0 0 1-0.341 0.273h-0.813c-0.287 0-0.444 0.158-0.444 0.353v1.568a0.353 0.353 0 0 0 0.353 0.353h0.9a0.356 0.356 0 0 1 0.343 0.273 5.651 5.651 0 0 0 0.617 1.486 0.354 0.354 0 0 1-0.048 0.435l-0.576 0.576a0.382 0.382 0 0 0-0.065 0.564l1.109 1.109a0.353 0.353 0 0 0 0.5 0l0.64-0.64a0.356 0.356 0 0 1 0.436-0.049 5.645 5.645 0 0 0 1.486 0.617 0.354 0.354 0 0 1 0.273 0.341v0.879c0 0.252 0.127 0.377 0.284 0.377h1.637a0.353 0.353 0 0 0 0.353-0.353v-0.9a0.356 0.356 0 0 1 0.273-0.343 5.645 5.645 0 0 0 1.483-0.613 0.354 0.354 0 0 1 0.435 0.048l0.576 0.576a0.383 0.383 0 0 0 0.564 0.065l1.109-1.109a0.353 0.353 0 0 0 0-0.5l-0.64-0.64a0.356 0.356 0 0 1-0.044-0.44 5.64 5.64 0 0 0 0.618-1.486 0.354 0.354 0 0 1 0.341-0.273h0.813c0.282 2e-3 0.44-0.156 0.44-0.351z" data-name="路径 14" />
                <circle transform="translate(158.92 150.99)" cx="3.132" cy="3.132" r="3.132" strokeLinecap="round" data-name="椭圆 2" />
            </g>
        </g>
    </SvgIcon>
}

function PartsInventoryListByPackageIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.63264 0 0 .63264 -179.96 -130.01)" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="1.2" data-name="组 4077">
            <rect transform="translate(303,220)" width="17" height="13" data-name="矩形 14404" />
            <path transform="translate(-9.856,-5.438)" d="m323.62 225v2.641h-4.621v-2.641" data-name="路径 1893" />
            <path transform="translate(0,-1.699)" d="m313.76 237.1v2.641a1.32 1.32 0 0 1-1.32 1.32h-21.125a1.32 1.32 0 0 1-1.32-1.32v-24.421a1.32 1.32 0 0 1 1.32-1.32h2.641" data-name="路径 1894" />
            <path transform="translate(-10.196,-1.699)" d="m320 214h2.641a1.32 1.32 0 0 1 1.32 1.32v3.961" data-name="路径 1895" />
            <path transform="translate(-1.359,-3.059)" d="m312.48 238.46v1.32h-18.484v-21.784h1.32" data-name="路径 1896" />
            <path transform="translate(-10.196,-3.059)" d="m320 218h1.32v2.641" data-name="路径 1897" />
            <path transform="translate(-2.719)" d="m309.22 211.64h-1.98a2.641 2.641 0 1 0-5.281 0h-1.98a1.98 1.98 0 0 0-1.98 1.98v1.98h13.2v-1.98a1.98 1.98 0 0 0-1.979-1.98z" data-name="路径 1898" />
            <line transform="translate(302,211)" y2="1" data-name="直线 891" />
            <line transform="translate(316,231)" x1="2" data-name="直线 892" />
            <line transform="translate(314,231)" x1="2" data-name="直线 893" />
        </g>
    </SvgIcon>
}

function DownloadInboundPlanIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.59549 0 0 .59549 -578.44 -319.31)" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="1.2" data-name="组 4107">
            <path transform="translate(-53.076,-81.66)" d="m1046.5 650.82" data-name="路径 1993" />
            <path transform="translate(-65.445,-6.845)" d="m1062.8 551.96h5.52v6.343" data-name="路径 1994" />
            <line transform="translate(983.5 545.11)" x2="12.244" data-name="直线 948" />
            <path transform="translate(0,-6.845)" d="m987.67 576h-11.326v-24.043h5.52" data-name="路径 1995" />
            <g transform="translate(981.86 542.91)" data-name="组 4105">
                <path transform="translate(-1042.2 -542.91)" d="m1056.9 547.31a0.817 0.817 0 0 1-0.817-0.817v-2.766a0.817 0.817 0 0 1 0.817-0.817 0.817 0.817 0 0 1 0.817 0.817v2.766a0.817 0.817 0 0 1-0.817 0.817z" data-name="路径 1996" />
                <path transform="translate(-999.04 -542.91)" d="m999.85 547.31a0.817 0.817 0 0 1-0.817-0.817v-2.766a0.817 0.817 0 0 1 0.817-0.817 0.817 0.817 0 0 1 0.817 0.817v2.766a0.817 0.817 0 0 1-0.817 0.817z" data-name="路径 1997" />
            </g>
            <line transform="translate(976.34 549.74)" x2="26.552" data-name="直线 949" />
            <rect transform="translate(984.4 553.49)" width="2.697" height="2.697" data-name="矩形 14416" />
            <rect transform="translate(979.92 558.1)" width="2.697" height="2.697" data-name="矩形 14417" />
            <rect transform="translate(984.4 558.1)" width="2.697" height="2.697" data-name="矩形 14418" />
            <rect transform="translate(984.4 562.7)" width="2.697" height="2.697" data-name="矩形 14419" />
            <rect transform="translate(979.92 562.7)" width="2.697" height="2.697" data-name="矩形 14420" />
            <g transform="translate(990.84 553.42)" data-name="组 4106">
                <line transform="translate(10.909,11.698)" x2="4.894" data-name="直线 950" />
                <line transform="translate(10.909,13.57)" x2="4.894" data-name="直线 951" />
                <path transform="translate(-1035.9 -628.35)" d="m1035.9 641.92" data-name="路径 1998" />
                <path transform="translate(-1035.9 -586.13)" d="m1053.8 588.29v12.88a0.69 0.69 0 0 1-0.69 0.69h-16.5a0.69 0.69 0 0 1-0.69-0.69v-14.348a0.7 0.7 0 0 1 0.7-0.7h16.5a0.69 0.69 0 0 1 0.69 0.69v12.888" data-name="路径 1999" />
                <path transform="translate(-1055.4 -586.13)" d="m1067.1 586.13h-5.346v2.848l0.784 0.688 0.63-0.63 0.63 0.63 0.63-0.63 0.63 0.63 0.63-0.63 0.63 0.63 0.784-0.688z" data-name="路径 2000" />
            </g>
        </g>
    </SvgIcon>
}

function DownloadOverallVisualisationIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.62411 0 0 .6241 -227.93 -28.623)" fill="none" stroke="currentColor" strokeWidth="1.2" data-name="组 4079">
            <path transform="translate(-13.17)" d="m409 54.7h4.024v-2.017a2.683 2.683 0 0 0-2.683-2.683" strokeMiterlimit="10" data-name="路径 1899" />
            <path d="m394.49 52.683a2.683 2.683 0 0 1 2.683-2.683h-22.805a2.683 2.683 0 0 0-2.683 2.683v24.817a2.683 2.683 0 0 1-2.683 2.683h22.805a2.683 2.683 0 0 0 2.683-2.683z" strokeLinecap="round" strokeLinejoin="round" data-name="路径 1900" />
            <g strokeMiterlimit="10">
                <line transform="translate(376,59)" x2="14" data-name="直线 894" />
                <line transform="translate(376,56)" x2="14" data-name="直线 895" />
                <line transform="translate(376,65)" x2="14" data-name="直线 896" />
                <line transform="translate(376,68)" x2="14" data-name="直线 897" />
                <line transform="translate(376,71)" x2="9" data-name="直线 898" />
            </g>
        </g>
    </SvgIcon>
}

function DashboardIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g data-name="组 70" strokeWidth="1.64" fill="none" stroke="currentColor">
            <g data-name="矩形 69" transform="matrix(.95238 0 0 1 2.038 2.04)">
                <rect width="10" height="10" rx="2.1" ry="2" stroke="none" />
                <rect x=".8" y=".8" width="8.4" height="8.4" rx="1.26" ry="1.2" strokeWidth="1.68" />
            </g>
            <g data-name="矩形 72" transform="matrix(.95238 0 0 1 2.038 13.04)">
                <rect width="10" height="10" rx="2.1" ry="2" stroke="none" />
                <rect x=".8" y=".8" width="8.4" height="8.4" rx="1.26" ry="1.2" strokeWidth="1.68" />
            </g>
            <g data-name="矩形 70" transform="matrix(.95238 0 0 1 12.514 2.04)">
                <rect width="10" height="10" rx="2.1" ry="2" stroke="none" />
                <rect x=".8" y=".8" width="8.4" height="8.4" rx="1.26" ry="1.2" strokeWidth="1.68" />
            </g>
            <g data-name="矩形 71" transform="matrix(.95238 0 0 1 12.514 13.04)">
                <rect width="10" height="10" rx="2.1" ry="2" stroke="none" />
                <rect x=".8" y=".8" width="8.4" height="8.4" rx="1.26" ry="1.2" strokeWidth="1.68" />
            </g>
        </g>
    </SvgIcon>
}

function CompanyListScreenIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.62949 0 0 .62948 -180.17 -81.252)" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="1.2" data-name="组 154">
            <path transform="translate(-.305 -5.494)" d="m320.18 153v10.422a2.084 2.084 0 0 1-2.084 2.084h-25.013a2.084 2.084 0 0 1-2.084-2.084v-10.422" data-name="路径 215" />
            <rect transform="translate(298,149)" width="3" height="4" data-name="矩形 149" />
            <rect transform="translate(310,149)" width="3" height="4" data-name="矩形 150" />
            <path transform="translate(-4.578)" d="m314.03 138.47v-2.084a1.389 1.389 0 0 0-1.39-1.39h-6.252a1.39 1.39 0 0 0-1.39 1.39v2.084" data-name="路径 216" />
            <line transform="translate(301,150)" x1="9" data-name="直线 57" />
            <path transform="translate(0,-1.526)" d="m297.64 151.12h-1.39c-2.319 0-6.253-1.26-6.253-3.474v-6.253a1.389 1.389 0 0 1 1.39-1.39h27.792a1.389 1.389 0 0 1 1.39 1.39v6.253c0 2.214-3.934 3.474-6.253 3.474h-1.39" data-name="路径 217" />
        </g>
    </SvgIcon>
}

function BusinessUnitListScreenIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.6295 0 0 .6295 -79.458 -29.012)" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="1.2" data-name="组 155">
            <path transform="translate(-5.189,-7.633)" d="m153.95 80.171v-2.084c0-0.792-1.911-2.08-3.473-2.08s-3.475 1.287-3.475 2.08v2.084" data-name="路径 218" />
            <circle transform="translate(137.64 60.032)" cx="7.643" cy="7.643" r="7.643" data-name="椭圆 57" />
            <path transform="translate(-5.799,-5.189)" d="m151.08 73.529a2.176 2.176 0 0 1-2.084-2.37v-0.791a2.1 2.1 0 1 1 4.169 0v0.791a2.176 2.176 0 0 1-2.085 2.37z" data-name="路径 219" />
            <path transform="translate(-2.747,-2.444)" d="m145.95 61.087c0-0.792-1.911-2.08-3.473-2.08s-3.475 1.287-3.475 2.08v2.084" data-name="路径 220" />
            <path transform="translate(-3.357)" d="m143.08 56.529a2.176 2.176 0 0 1-2.084-2.37v-0.791a2.1 2.1 0 1 1 4.169 0v0.791a2.176 2.176 0 0 1-2.085 2.37z" data-name="路径 221" />
            <path transform="translate(-7.63,-2.444)" d="m155 61.087c0-0.792 1.911-2.08 3.473-2.08s3.475 1.287 3.475 2.08v2.084" data-name="路径 222" />
            <path transform="translate(-8.241)" d="m159.08 56.529a2.176 2.176 0 0 0 2.084-2.37v-0.791a2.1 2.1 0 1 0-4.169 0v0.791a2.176 2.176 0 0 0 2.085 2.37z" data-name="路径 223" />
            <path transform="translate(-10.682,-7.328)" d="m171.25 79.171v-2.084c0-0.792-1.911-2.08-3.473-2.08a4.819 4.819 0 0 0-2.78 1.068" data-name="路径 224" />
            <path transform="translate(-10.988,-4.883)" d="m168.08 72.529a2.176 2.176 0 0 1-2.084-2.37v-0.791a2.1 2.1 0 1 1 4.169 0v0.791a2.176 2.176 0 0 1-2.085 2.37z" data-name="路径 225" />
            <path transform="translate(0,-7.328)" d="m130 79.171v-2.084c0-0.792 1.911-2.08 3.473-2.08a4.819 4.819 0 0 1 2.781 1.068" data-name="路径 226" />
            <path transform="translate(-.61 -4.883)" d="m134.08 72.529a2.176 2.176 0 0 0 2.084-2.37v-0.791a2.1 2.1 0 1 0-4.169 0v0.791a2.176 2.176 0 0 0 2.085 2.37z" data-name="路径 227" />
            <path transform="translate(-8.479,-9.157)" d="m160.24 81 5.9 5.9a1.39 1.39 0 0 1 0 1.966l-0.491 0.491a1.389 1.389 0 0 1-1.965 0l-5.9-5.9" data-name="路径 228" />
        </g>
    </SvgIcon>
}

function CustomerListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.6383 0 0 .6383 -183.72 -29.532)" fill="none" stroke="currentColor" strokeWidth="1.2" data-name="组 156">
            <g strokeLinejoin="round">
                <line transform="translate(299.24 78.951)" x2="20.495" data-name="直线 58" />
                <path transform="translate(0,-9.649)" d="m293 90.382v-5.588a2.648 2.648 0 0 1 2.617-2.794h2.794l3.492 4.191h4.889s0.4 2.794-2.794 2.794h-4.191l-2.707-2.794" data-name="路径 229" />
                <path transform="translate(-.302 -5.729)" d="m297.14 75.985a3.059 3.059 0 0 0 3.143-2.993v-1a3.147 3.147 0 0 0-6.286 0v1a3.059 3.059 0 0 0 3.143 2.993z" data-name="路径 230" />
                <path transform="translate(-5.329,-8.141)" d="m310.67 82.588 2.146-5.588h12.573l-3.353 8.382h-7.646" data-name="路径 231" />
                <line transform="translate(306.37 56.674)" x2="8.02" data-name="直线 59" />
                <line transform="translate(306.37 54.001)" x2="8.02" data-name="直线 60" />
                <line transform="translate(306.37 59.347)" x2="4.455" data-name="直线 61" />
            </g>
            <path transform="translate(-3.618)" d="m314.43 50c-5.207 0-9.429 3.127-9.429 6.985a5.921 5.921 0 0 0 1.99 4.282 26.317 26.317 0 0 1-0.982 3.879c-0.159 0.5 0.191 1.028 0.606 0.9a10.819 10.819 0 0 0 4.456-2.54 12.346 12.346 0 0 0 3.36 0.464c5.208 0 9.429-3.127 9.429-6.985s-4.222-6.985-9.43-6.985z" strokeMiterlimit="10" data-name="路径 232" />
        </g>
    </SvgIcon>
}

function SupplierListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.64214 0 0 .64212 -131.82 -29.478)" fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="1.2" data-name="组 157">
            <rect transform="translate(224.34 64.596)" width="14.608" height="12.417" data-name="矩形 151" />
            <line transform="translate(236.76 74.821)" x1="1.461" data-name="直线 62" />
            <line transform="translate(234.56 74.821)" x1="1.461" data-name="直线 63" />
            <rect transform="translate(209,64.596)" width="15.338" height="12.417" data-name="矩形 152" />
            <path transform="translate(-2.734,-6.494)" d="m221.61 71v2.633h-4.608v-2.633" data-name="路径 233" />
            <path transform="translate(-10.595,-6.494)" d="m244.61 71v2.633h-4.608v-2.633" data-name="路径 234" />
            <rect transform="translate(217.03 52.179)" width="15.338" height="12.417" data-name="矩形 153" />
            <line transform="translate(229.45 62.405)" x1="1.461" data-name="直线 64" />
            <line transform="translate(227.26,62.405)" x1="1.461" data-name="直线 65" />
            <path transform="translate(-6.836)" d="m233.61 52v2.633h-4.608v-2.633" data-name="路径 235" />
            <line transform="translate(221.42 74.821)" x1="1.461" data-name="直线 66" />
            <line transform="translate(219.23 74.821)" x1="1.461" data-name="直线 67" />
        </g>
    </SvgIcon>
}

function DistributionCentreListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.63008 0 0 .63006 -28.658 -80.076)" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="1.2" data-name="组 158">
            <rect transform="translate(57.675 153.7)" width="7.097" height="5.52" data-name="矩形 154" />
            <rect transform="translate(64.772 153.7)" width="6.309" height="5.52" data-name="矩形 155" />
            <rect transform="translate(60.829 148.18)" width="7.097" height="5.52" data-name="矩形 156" />
            <path d="M 80.057,143.8 64.528,133 49,143.8" data-name="路径 236" />
            <line transform="translate(51.366 142.66)" y2="17.349" data-name="直线 68" />
            <line transform="translate(78.178 142.66)" y2="17.349" data-name="直线 69" />
            <path transform="translate(-2.599,-5.847)" d="m57 165.85v-14.853h20.254v14.853" data-name="路径 237" />
        </g>
    </SvgIcon>
}

function GlobalPartsListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.61035 0 0 .61034 -28.075 -28.462)" fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="1.2" data-name="组 160">
            <g strokeLinecap="round">
                <path transform="translate(-1.177,-7.702)" d="m57.574 88.292v-1.792a1.073 1.073 0 0 1 1.072-1.072h5a1.072 1.072 0 0 0 1.072-1.072v-0.715a1.072 1.072 0 0 1 1.072-1.072h0.715a1.073 1.073 0 0 0 1.075-1.069v-1.428a1.073 1.073 0 0 0-1.072-1.072h-5a1.073 1.073 0 0 0-1.072 1.072 1.073 1.073 0 0 1-1.072 1.072h-5.364" data-name="路径 247" />
                <path transform="translate(-6.882,-7.987)" d="m74 88.577v-1.43a1.429 1.429 0 0 1 1.429-1.429h3.571a1.429 1.429 0 0 0 1.429-1.429 1.429 1.429 0 0 0-1.429-1.43h-0.715a1.429 1.429 0 0 1-1.429-1.429 1.429 1.429 0 0 1 1.432-1.43h5" data-name="路径 248" />
                <path transform="translate(-.321 -5.99)" d="m51 86.58c0-7.895 6.56-13.58 14.652-13.58s14.648 5.685 14.648 13.58" data-name="路径 249" />
            </g>
            <rect transform="translate(58.541,52)" width="13.58" height="12.151" data-name="矩形 157" />
            <path transform="translate(-5.17)" d="m73 52v2.859h-5v-2.859" data-name="路径 250" />
            <line transform="translate(68.548,61.292)" x1="1.429" data-name="直线 73" />
            <line transform="translate(66.403,61.292)" x1="1.429" data-name="直线 74" />
            <g strokeLinecap="round">
                <path transform="translate(-1.608,-4.279)" d="m55.513 68.429a20.739 20.739 0 0 1 2.492-1.429" data-name="路径 251" />
                <path transform="translate(0,-5.42)" d="m49.875 73.083a21.208 21.208 0 0 1 2.135-2.083" data-name="路径 252" />
                <path transform="translate(-11.732,-5.638)" d="m91 71.763a21.215 21.215 0 0 1 2.175 2.314" data-name="路径 253" />
                <path transform="translate(-9.734,-4.279)" d="m84 67a20.72 20.72 0 0 1 2.859 1.68" data-name="路径 254" />
            </g>
        </g>
    </SvgIcon>
}

function UnitPartsListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.6102 0 0 .61021 -76.036 -76.213)" fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="1.2" data-name="组 165">
            <path transform="translate(-4.933)" d="m144.97 138.1 1.366-3.3 2.005 0.668a8.5 8.5 0 0 1 1.987-1.984l-0.669-2.008 3.3-1.365 0.947 1.892a8.522 8.522 0 0 1 2.808 0l0.947-1.89 3.3 1.366-0.668 2.005a8.506 8.506 0 0 1 1.984 1.987l2.008-0.668 1.365 3.3-1.892 0.947a8.5 8.5 0 0 1 0 2.808l1.89 0.946-1.365 3.3-2.006-0.668a8.532 8.532 0 0 1-1.987 1.984l0.668 2.007-3.3 1.365-0.947-1.892a8.5 8.5 0 0 1-2.808 0l-0.946 1.89-3.3-1.365 0.668-2.006a8.505 8.505 0 0 1-1.984-1.987l-2.007 0.668-1.366-3.3 1.892-0.947a8.5 8.5 0 0 1 0-2.807z" data-name="路径 279" />
            <path transform="translate(-.143 -7.479)" d="m129.6 159.41 0.9-2.183 1.687 0.7a5.2 5.2 0 0 1 1.213-1.212l-0.7-1.689 2.182-0.9 0.7 1.689a5.19 5.19 0 0 1 1.714 0l0.7-1.687 2.183 0.9-0.7 1.687a5.2 5.2 0 0 1 1.212 1.213l1.688-0.7 0.9 2.182-1.689 0.7a5.19 5.19 0 0 1 0 1.714l1.687 0.7-0.9 2.182-1.687-0.7a5.209 5.209 0 0 1-1.213 1.212l0.7 1.688-2.182 0.9-0.7-1.689a5.19 5.19 0 0 1-1.714 0l-0.7 1.687-2.182-0.9 0.7-1.687a5.18 5.18 0 0 1-1.21-1.213l-1.689 0.7-0.9-2.182 1.689-0.7a5.189 5.189 0 0 1 0-1.714z" data-name="路径 280" />
            <path transform="translate(0,-1.558)" d="m132.46 145.7-1.51 0.974-1.259-1.952 1.51-0.974a5.119 5.119 0 0 1-0.3-1.658l-1.755-0.378 0.489-2.271 1.755 0.379a5.1 5.1 0 0 1 0.96-1.385l-0.974-1.51 1.952-1.259 0.974 1.51a5.1 5.1 0 0 1 1.658-0.3l0.379-1.755 2.27 0.489-0.379 1.755" data-name="路径 281" />
            <path transform="translate(-2.202,-9.537)" d="m136.38 162.14a2.295 2.295 0 1 0 3-1.241 2.3 2.3 0 0 0-3 1.241z" data-name="路径 282" />
            <circle transform="translate(144.9 134.94)" cx="5.507" cy="5.507" r="5.507" data-name="椭圆 59" />
            <path transform="translate(-2.148,-3.739)" d="m138.31 146.24a2.065 2.065 0 1 1 0.973-3.741" data-name="路径 284" />
        </g>
    </SvgIcon>
}

function ContractListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.64639 0 0 .64639 -28.931 -185.15)" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="1.2" data-name="组 161">
            <path d="m71.343 297v-6h-21.343v28.012h21.343v-10" data-name="路径 255" />
            <path transform="translate(-5.262,-2.175)" d="m83.833 297.86c-0.782-0.781-2.737-0.093-4.3 1.469l-8.426 8.524c-2.741 2.741-5.969 7.236-5.188 8.018s5.276-2.446 8.018-5.188l8.426-8.524c1.563-1.562 2.251-3.517 1.47-4.299z" data-name="路径 256" />
            <line transform="translate(59.205 313.69)" x2="1.453" y1="1.55" strokeLinecap="round" data-name="直线 75" />
            <path transform="translate(-11.649,-3.071)" d="m85.92 300.22 3.773 3.773a1.334 1.334 0 0 1 0 1.887l-4.716 4.72" data-name="路径 257" />
            <line transform="translate(65.374 306.14)" x2="2.83" y2="2.83" data-name="直线 76" />
            <line transform="translate(78.571 294.34)" x2="1.346" y1="1.346" data-name="直线 77" />
            <line transform="translate(53,315)" x1="4" data-name="直线 78" />
            <line transform="translate(57,296)" x2="7" data-name="直线 79" />
            <line transform="translate(54,299)" x2="13" data-name="直线 80" />
            <line transform="translate(54,302)" x2="13" data-name="直线 81" />
            <line transform="translate(54,304)" x2="10" data-name="直线 82" />
            <line transform="translate(54,307)" x2="7" data-name="直线 83" />
        </g>
    </SvgIcon>
}

function ContractRouteListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.61076 0 0 .61076 -75.775 -166.81)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" data-name="组 141">
            <circle transform="translate(139.94 281.12)" cx="3.772" cy="3.772" r="3.772" data-name="椭圆 56" />
            <path transform="translate(-1.571,-8.798)" d="m151.37 305h4.543a3.086 3.086 0 0 1 3.084 3.086 3.086 3.086 0 0 1-3.086 3.086h-17.83a3.086 3.086 0 0 0-3.084 3.086 3.086 3.086 0 0 0 3.086 3.086h5.143" data-name="路径 213" />
            <line transform="translate(130 296.02)" x2="6.951" data-name="直线 55" />
            <line transform="translate(145.64,308.18)" x2="3.475" data-name="直线 56" />
            <path transform="translate(-2.514)" d="m154.46 285.23a8.229 8.229 0 1 0-16.458 0c0 6.172 8.229 12.344 8.229 12.344s8.229-6.173 8.229-12.344z" data-name="路径 214" />
        </g>
    </SvgIcon>
}

function FobSettingIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <switch transform="matrix(.54021 0 0 .5402 2.3571 -.78676)">
            <g>
                <g transform="translate(-465.82 -24.273)">
                    <path d="m492.2 39.2h-18c-0.3 0-0.6-0.3-0.6-0.6v-3.9c0-1.1 0.9-2 2-2.1h3.7c0.4-2.1 2.4-3.5 4.5-3.1 1.6 0.3 2.8 1.5 3.1 3.1h3.7c1.1 0 2 0.9 2 2.1v3.9c0.2 0.4 0 0.6-0.4 0.6zm-17.4-1.2h16.8v-3.3c0-0.5-0.4-0.9-0.9-0.9h-3.7c-0.6 0-1.1-0.4-1.2-0.9v-0.1c-0.3-1.5-1.7-2.4-3.1-2.1-1.1 0.2-2 1.1-2.1 2.1v0.1c-0.1 0.5-0.6 0.9-1.2 0.9h-3.7c-0.5 0-0.9 0.4-0.9 0.9z" />
                    <path d="m482 64.1h-11.4c-0.3 0-0.6-0.3-0.6-0.6v-28c0-0.3 0.3-0.6 0.6-0.6h1.7v1.2h-1.1v26.7h10.8z" />
                    <path d="m483.3 33.8h-0.1c-0.3 0-0.6-0.3-0.6-0.6s0.3-0.6 0.6-0.6h0.1c0.3 0 0.6 0.3 0.6 0.6s-0.3 0.6-0.6 0.6z" />
                    <path d="m496 64.1h-14.9v-1.2h14.3v-26.8h-1.1v-1.2h1.7c0.3 0 0.6 0.3 0.6 0.6v27.9c0 0.4-0.3 0.7-0.6 0.7z" />
                </g>
                <g transform="translate(-139.44 -129.52)" fill="#fff" stroke="currentColor" strokeLinejoin="round" strokeWidth="1.2">
                    <path d="m160.7 164.1 0.8-1.8 1.1 0.4c0.3-0.4 0.7-0.8 1.1-1.1l-0.4-1.1 1.8-0.8 0.5 1c0.5-0.1 1-0.1 1.6 0l0.5-1 1.8 0.8-0.4 1.1c0.4 0.3 0.8 0.7 1.1 1.1l1.1-0.4 0.8 1.8-1 0.5c0.1 0.5 0.1 1 0 1.6l1 0.5-0.8 1.8-1.1-0.4c-0.3 0.4-0.7 0.8-1.1 1.1l0.4 1.1-1.8 0.8-0.5-1c-0.5 0.1-1 0.1-1.6 0l-0.5 1-1.8-0.8 0.4-1.1c-0.4-0.3-0.8-0.7-1.1-1.1l-1.1 0.4-0.8-1.8 1-0.5c-0.1-0.5-0.1-1 0-1.6z" />
                    <circle cx="166.5" cy="165.4" r="3.1" />
                </g>
            </g>
        </switch>
    </SvgIcon>
}

function PaymentTermsListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.61999 0 0 .61999 -276 -153.24)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" data-name="组 170">
            <path transform="translate(-2.273,-12.124)" d="m455 290.45h0.3a6.209 6.209 0 0 1 1.841 0.279l9.415 3.306a3.727 3.727 0 0 0 2.985-0.42l11.9-7.145a1.889 1.889 0 0 0 0.422-2.85 1.941 1.941 0 0 0-2.345-0.4l-4.686 2.5" data-name="路径 306" />
            <path transform="translate(-2.273,-11.366)" d="m463.7 287.21h9.211a1.934 1.934 0 0 0 1.967-1.763 1.864 1.864 0 0 0-1.861-1.963h-6.325a2.425 2.425 0 0 1-1.7-0.675 7.233 7.233 0 0 0-5.021-1.81 7.824 7.824 0 0 0-3.922 1 1.928 1.928 0 0 1-0.971 0.243h-0.078" data-name="路径 307" />
            <path transform="translate(0,-11.366)" d="m449 281h3.727v11.18h-2.485" data-name="路径 308" />
            <ellipse transform="translate(457 251)" cx="10" cy="9" rx="10" ry="9" data-name="椭圆 62" />
            <path transform="translate(-9.85,-4.168)" d="m478.73 262h-2.485a1.2425 1.2425 0 1 0 0 2.485h1.242a1.2425 1.2425 0 1 1 0 2.485h-2.484" data-name="路径 309" />
            <line transform="translate(467 256)" y2="2" data-name="直线 102" />
            <line transform="translate(467 263)" y2="1" data-name="直线 103" />
        </g>
    </SvgIcon>
}

function ShippingRouteListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.6295 0 0 .62952 -28.468 -131.09)" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="1.2" data-name="组 162">
            <line transform="translate(52,233)" x1="2" data-name="直线 84" />
            <path transform="translate(0,-4.36)" d="m51.658 232.32-2.658-0.665v-2.658" data-name="路径 258" />
            <path transform="translate(-3.019,-7.379)" d="m63.981 241.32v-3.323h-5.981v3.323" data-name="路径 259" />
            <line transform="translate(62,233)" x2="2" data-name="直线 85" />
            <path transform="translate(-7.715,-4.36)" d="m72 232.32 2.658-0.665v-2.658" data-name="路径 260" />
            <path transform="translate(-.671 -10.063)" d="m51 246v1.994a0.665 0.665 0 0 0 0.665 0.665h1.994a0.665 0.665 0 0 0 0.665-0.665v-1.994" data-name="路径 261" />
            <path transform="translate(-6.708,-10.063)" d="m69 246v1.994a0.665 0.665 0 0 0 0.665 0.665h1.994a0.665 0.665 0 0 0 0.665-0.665v-1.994" data-name="路径 262" />
            <path transform="translate(-13.081,-10.063)" d="m88 246v1.994a0.665 0.665 0 0 0 0.665 0.665h1.994a0.665 0.665 0 0 0 0.665-0.665v-1.994" data-name="路径 263" />
            <rect transform="translate(54,225)" width="8" height="3" data-name="矩形 158" />
            <path transform="translate(0,-1.342)" d="m64.285 220v9.969l0.665 0.665a1.834 1.834 0 0 1 0.665 1.329v2.658h1.329v2.658h-17.944v-2.658h1.329v-2.658a1.834 1.834 0 0 1 0.665-1.329l0.665-0.665v-9.969" data-name="路径 264" />
            <path transform="translate(-1.342,-2.683)" d="m65.627 225.33-0.817-0.759a2.123 2.123 0 0 0-1.841-0.57h-7.31a2.123 2.123 0 0 0-1.841 0.57l-0.817 0.759" data-name="路径 265" />
            <path d="m49 223.31v-7.31h17.944v7.31" data-name="路径 266" />
            <path transform="translate(-9.056)" d="m76 216h0.665l11.962 7.975v11.962h-11.3" data-name="路径 267" />
        </g>
    </SvgIcon>
}

function CargoStatusSettingIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.17848 0 0 .17848 -1.2289 -1.4805)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
            <polyline points="77.75 61.11 76.47 62.86 73.94 59.41 70.95 62.86 68.88 59.41 66.21 62.86 63.81 59.41 61.28 62.86 58.75 58.96 58.75 50.2 75.04 50.2" />
            <line x1="97.53" x2="81.53" y1="91.96" y2="91.96" />
            <line x1="97.59" x2="81.58" y1="96.72" y2="96.72" />
            <polyline points="81.24 49.74 103.04 49.74 103.04 61.53" />
            <polyline points="103.04 68.81 103.04 105.43 72.6 105.43" />
            <polyline points="35.98 75.68 35.98 49.74 75.06 49.74" />
            <line x1="67.7" x2="54.97" y1="105.43" y2="105.43" />
            <line x1="35.98" x2="35.98" y1="102.04" y2="86.42" />
            <path d="m75.06 49.74c-0.01 0.16-0.02 0.31-0.02 0.47" />
            <path d="m77.75 61.11c-1.74-2.93-2.76-6.34-2.76-9.99 0-0.31 0.03-0.61 0.05-0.92" />
            <path d="m104.95 67.77c-0.61 0.38-1.25 0.72-1.91 1.04" />
            <path d="m75.06 49.74c0.71-10.19 9.18-18.24 19.55-18.24s19.62 8.78 19.62 19.62c0 4.11-1.27 7.92-3.43 11.08" />
            <path d="m77.75 61.11c3.42 5.76 9.68 9.63 16.86 9.63 3.02 0 5.87-0.7 8.43-1.93" />
            <path d="m110.8 62.19c-1.54 2.24-3.54 4.14-5.85 5.57" />
            <path d="m106.32 57.71c1.1-1.95 1.74-4.2 1.74-6.6 0-7.42-6.02-13.44-13.44-13.44-6.96 0-12.68 5.29-13.37 12.06" />
            <path d="m81.24 49.74c-0.05 0.45-0.07 0.92-0.07 1.38 0 7.42 6.02 13.44 13.44 13.44 2.08 0 4.04-0.49 5.8-1.33" />
            <path d="m100.41 63.23c0.95-0.46 1.82-1.05 2.63-1.71" />
            <path d="m106.32 57.71c-0.84 1.48-1.97 2.75-3.28 3.81" />
            <path d="m110.8 62.19 18.59 18.59c0.85 0.85 0.85 2.22 0 3.07l-2.64 2.64c-0.85 0.85-2.22 0.85-3.07 0l-18.73-18.73" />
            <line x1="113.9" x2="109.31" y1="65.92" y2="70.5" />
            <path d="m31.95 99.15c-1.03-1.37-1.64-3.07-1.64-4.92 0-3.65 2.38-6.74 5.67-7.82" />
            <path d="m45.12 99.15c1.03-1.37 1.64-3.07 1.64-4.92 0-4.54-3.68-8.23-8.23-8.23-0.89 0-1.75 0.15-2.56 0.41" />
            <path d="m45.12 99.15c-1.5 2.01-3.89 3.31-6.59 3.31-0.9 0-1.75-0.16-2.56-0.42" />
            <path d="m35.98 102.04c-1.62-0.53-3.02-1.55-4.03-2.89" />
            <path d="m35.98 75.68c-0.92 0.06-1.65 0.81-1.65 1.74v3.25c-0.77 0.23-1.51 0.54-2.21 0.89l-2.46-2.46c-0.69-0.69-1.8-0.69-2.48 0l-3.3 3.3c-0.69 0.69-0.69 1.8 0 2.48l2.34 2.34c-0.48 0.85-0.87 1.76-1.16 2.71h-3.34c-0.97 0-1.76 0.79-1.76 1.76v4.67c0 0.97 0.79 1.76 1.76 1.76h3.34c0.11 0.35 0.24 0.7 0.37 1.03" />
            <path d="m55.08 89.94h-3.31c-0.28-0.91-0.64-1.78-1.09-2.6l2.69-2.69c0.69-0.69 0.69-1.8 0-2.48l-3.3-3.3c-0.69-0.69-1.8-0.69-2.48 0l-2.75 2.75c-0.74-0.38-1.52-0.71-2.33-0.95v-3.25c0-0.97-0.79-1.76-1.76-1.76h-4.67s-0.07 0-0.11 0.01" />
            <path d="m25.44 99.15c0.18 0.44 0.37 0.87 0.58 1.29l-2.2 2.2c-0.69 0.69-0.69 1.8 0 2.48l3.3 3.3c0.69 0.69 1.8 0.69 2.48 0l2.14-2.14c0.82 0.45 1.69 0.81 2.6 1.09v3.66c0 0.97 0.79 1.76 1.76 1.76h4.67c0.97 0 1.76-0.79 1.76-1.76v-3.66c0.92-0.28 1.79-0.66 2.62-1.11" />
            <path d="m67.7 105.43c-0.68 2.13-2.41 3.88-4.74 4.46-3.45 0.86-6.93-1.12-7.99-4.46" />
            <path d="m67.7 105.43c0.36-1.14 0.43-2.4 0.12-3.64-0.9-3.58-4.53-5.75-8.11-4.85s-5.75 4.53-4.85 8.11c0.03 0.13 0.08 0.26 0.12 0.39" />
            <path d="m55.08 89.94c0.18-0.35 0.49-0.63 0.91-0.74l3.68-0.92c0.76-0.19 1.54 0.27 1.73 1.04l0.66 2.63c0.81 0.04 1.6 0.17 2.36 0.38l1.38-2.31c0.4-0.68 1.28-0.9 1.96-0.49l3.25 1.95c0.68 0.4 0.9 1.28 0.49 1.96l-1.45 2.43c0.42 0.49 0.8 1.01 1.14 1.57l2.56-0.64c0.76-0.19 1.54 0.27 1.73 1.04l0.92 3.68c0.19 0.76-0.27 1.54-1.04 1.73l-2.56 0.64c-0.03 0.53-0.1 1.06-0.2 1.57" />
            <path d="m72.6 105.43c-0.03 0.15-0.05 0.3-0.09 0.45l2.71 1.62c0.68 0.4 0.9 1.28 0.49 1.96l-1.95 3.25c-0.4 0.68-1.28 0.9-1.96 0.49l-2.65-1.59c-0.56 0.51-1.17 0.98-1.83 1.37l0.71 2.82c0.19 0.76-0.27 1.54-1.04 1.73l-3.68 0.92c-0.76 0.19-1.54-0.27-1.73-1.04l-0.71-2.82c-0.65-0.03-1.29-0.12-1.92-0.27l-1.5 2.51c-0.4 0.68-1.28 0.9-1.96 0.49l-3.25-1.95c-0.68-0.4-0.9-1.28-0.49-1.96l1.44-2.4c-0.54-0.58-1.03-1.21-1.45-1.9l-2.89 0.72c-0.76 0.19-1.54-0.27-1.73-1.04l-0.22-0.88" />
            <path d="m55.08 89.94c-0.15 0.3-0.22 0.64-0.13 0.99l0.66 2.63c-0.59 0.36-1.14 0.76-1.65 1.21l-2.17-1.3c-0.68-0.4-1.55-0.18-1.96 0.49l-1.95 3.25c-0.4 0.68-0.18 1.55 0.49 1.96l2.11 1.26c-0.19 0.73-0.31 1.49-0.35 2.26l-2.89 0.72c-0.76 0.19-1.23 0.97-1.04 1.73l0.07 0.28" />
            <line x1="46.3" x2="46.93" y1="105.43" y2="107.94" />
        </g>
    </SvgIcon>
}

function RequestListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.61911 0 0 .61911 -177.57 -28.584)" fill="none" stroke="currentColor" strokeWidth="1.2" data-name="组 166">
            <path transform="translate(0,-5.862)" d="m310.53 77.073a8.478 8.478 0 0 1-9.369 0c-0.782-0.463-10.16-7.073-10.16-7.073v14.845a2.121 2.121 0 0 0 2.121 2.121h25.449a2.121 2.121 0 0 0 2.121-2.121v-14.845s-9.379 6.614-10.162 7.073z" strokeLinejoin="round" data-name="路径 285" />
            <g strokeMiterlimit="10">
                <line transform="translate(311,71.104)" x2="7" y2="7" data-name="直线 89" />
                <line transform="translate(294,71.104)" x1="7" y2="7" data-name="直线 90" />
                <path transform="translate(-10.259,-3.381)" d="m330.95 67.519v-0.707a2.4 2.4 0 0 0-1.027-1.91l-3.922-3.368" data-name="路径 286" />
                <path transform="translate(0,-3.38)" d="m291 67.518v-0.707a2.4 2.4 0 0 1 1.027-1.91l3.921-3.369" data-name="路径 287" />
                <path transform="translate(-10.258)" d="m328.12 53.535h3.535v-0.707a2.787 2.787 0 0 0-2.828-2.828h-2.828" data-name="路径 288" />
                <path transform="translate(-2.052)" d="m298 67.673v-14.138a3.535 3.535 0 0 1 3.535-3.535h19.793a3.535 3.535 0 0 0-3.535 3.535v14.138" data-name="路径 289" />
                <line transform="translate(300,56.104)" x2="12" data-name="直线 91" />
                <line transform="translate(300,60.104)" x2="12" data-name="直线 92" />
                <line transform="translate(300.19,63.431)" x2="6.912" data-name="直线 93" />
            </g>
        </g>
    </SvgIcon>
}

function RequestAddPartsIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <switch transform="matrix(.63573 0 0 .63573 1.9364 2.5809)">
            <g>
                <g transform="translate(-399.22 -25.383)">
                    <g transform="translate(399.82 25.984)">
                        <g transform="translate(2.709)">
                            <g transform="translate(0,5.229)">
                                <path d="m3.4 22.5c-0.5 0-0.9-0.2-1.3-0.5l-2.2-2.2c-0.7-0.7-0.7-1.8 0-2.5l17.7-17.7c0.2-0.2 0.6-0.2 0.8 0s0.2 0.6 0 0.8l-17.6 17.7c-0.2 0.2-0.2 0.6 0 0.8l2.2 2.2c0.2 0.2 0.6 0.2 0.8 0l17.7-17.6c0.2-0.2 0.6-0.2 0.8 0s0.2 0.6 0 0.8l-17.6 17.7c-0.3 0.3-0.8 0.5-1.3 0.5z" />
                            </g>
                            <g transform="translate(16.117)">
                                <path d="m7.9 11.6c-0.3 0-0.6-0.1-0.8-0.3l-7.4-7.4c-0.4-0.4-0.4-1.1 0-1.6l2.7-2.7c0.4-0.4 1.1-0.4 1.6 0l7.4 7.4c0.4 0.4 0.4 1.1 0 1.6l-2.7 2.7c-0.3 0.2-0.5 0.3-0.8 0.3zm-7.3-8.4 7.2 7.2 2.5-2.5-7.1-7.3z" />
                            </g>
                            <g transform="translate(17.779,1.662)">
                                <path d="m0 4c-0.2 0-0.3-0.1-0.4-0.2-0.2-0.2-0.2-0.6 0-0.8l3.4-3.4c0.2-0.2 0.6-0.2 0.8 0s0.2 0.6 0 0.8l-3.4 3.4c-0.1 0.1-0.2 0.2-0.4 0.2z" />
                            </g>
                            <g transform="translate(21.507,5.39)">
                                <path d="m0 4c-0.2 0-0.3-0.1-0.4-0.2-0.2-0.2-0.2-0.6 0-0.8l3.4-3.4c0.2-0.2 0.6-0.2 0.8 0s0.2 0.6 0 0.8l-3.4 3.4c-0.1 0.1-0.2 0.2-0.4 0.2z" />
                            </g>
                        </g>
                        <g transform="translate(0 .856)">
                            <path d="m3.6-0.6h6.7c0.3 0 0.6 0.2 0.7 0.4l3.3 5.8c0.1 0.3 0.1 0.6 0 0.8l-3.3 5.8c-0.1 0.3-0.4 0.4-0.7 0.4h-6.7c-0.3 0-0.6-0.2-0.7-0.4l-3.3-5.8c-0.1-0.3-0.1-0.6 0-0.8l3.3-5.8c0.1-0.2 0.4-0.4 0.7-0.4zm6.5 1.2h-6.3l-3.1 5.4 3.1 5.4h6.3l3.1-5.4z" />
                            <g transform="translate(3.694,2.799)">
                                <path d="m3.2-0.6c2.1 0 3.8 1.7 3.8 3.8s-1.7 3.9-3.8 3.9-3.8-1.7-3.8-3.8c0-2.2 1.7-3.9 3.8-3.9zm0 6.5c1.5 0 2.6-1.2 2.6-2.6 0-1.5-1.2-2.6-2.6-2.6-1.5 0-2.6 1.2-2.6 2.6s1.2 2.6 2.6 2.6z" />
                            </g>
                        </g>
                        <g transform="translate(16.586,16.531)">
                            <path d="m3.6-0.6h6.7c0.3 0 0.6 0.2 0.7 0.4l3.3 5.8c0.1 0.3 0.1 0.6 0 0.8l-3.3 5.8c-0.2 0.3-0.4 0.4-0.7 0.4h-6.7c-0.3 0-0.6-0.2-0.7-0.4l-3.3-5.8c-0.1-0.3-0.1-0.6 0-0.8l3.3-5.8c0.1-0.2 0.4-0.4 0.7-0.4zm6.5 1.2h-6.3l-3.1 5.4 3.1 5.4h6.3l3.1-5.4z" />
                            <g transform="translate(3.694,2.799)">
                                <path d="m3.2-0.6c2.1 0 3.8 1.7 3.8 3.8s-1.7 3.9-3.8 3.9-3.8-1.7-3.8-3.8c0-2.2 1.7-3.9 3.8-3.9zm0 6.5c1.5 0 2.6-1.2 2.6-2.6 0-1.5-1.2-2.6-2.6-2.6-1.5 0-2.6 1.2-2.6 2.6s1.2 2.6 2.6 2.6z" />
                            </g>
                        </g>
                    </g>
                    <path d="m421.2 39.4c-0.1 0-0.2 0-0.3-0.1-1.9-1.1-3.7-2.4-5.2-4-0.2-0.3-0.2-0.6 0.1-0.8s0.6-0.2 0.8 0.1c1.4 1.5 3.1 2.8 4.9 3.8 0.3 0.2 0.4 0.5 0.2 0.8 0 0.1-0.2 0.2-0.5 0.2z" />
                    <path d="m419.7 40.9c-0.1 0-0.2 0-0.3-0.1-1.9-1.1-3.7-2.4-5.2-4-0.2-0.3-0.2-0.6 0.1-0.8s0.6-0.2 0.8 0.1c1.4 1.5 3.1 2.8 4.9 3.8 0.3 0.2 0.4 0.5 0.2 0.8-0.1 0.1-0.3 0.2-0.5 0.2z" />
                    <path d="m418.2 42.5c-0.1 0-0.2 0-0.3-0.1-1.9-1.1-3.7-2.4-5.2-4-0.2-0.3-0.2-0.6 0.1-0.8s0.6-0.2 0.8 0.1c1.4 1.5 3.1 2.8 4.9 3.8 0.3 0.2 0.4 0.5 0.2 0.8-0.1 0-0.3 0.2-0.5 0.2z" />
                    <path d="m416.7 44c-0.1 0-0.2 0-0.3-0.1-1.9-1.1-3.7-2.4-5.2-4-0.2-0.3-0.2-0.6 0.1-0.8s0.6-0.2 0.8 0.1c1.4 1.5 3.1 2.8 4.9 3.8 0.3 0.2 0.4 0.5 0.2 0.8-0.1 0-0.3 0.2-0.5 0.2z" />
                    <path d="m415.2 45.5c-0.1 0-0.2 0-0.3-0.1-1.9-1.1-3.7-2.4-5.2-4-0.2-0.3-0.2-0.6 0.1-0.8s0.6-0.2 0.8 0.1c1.4 1.5 3.1 2.8 4.9 3.8 0.3 0.2 0.4 0.5 0.2 0.8-0.1 0.1-0.3 0.2-0.5 0.2z" />
                    <path d="m413.7 47c-0.1 0-0.2 0-0.3-0.1-1.9-1.1-3.7-2.4-5.2-4-0.2-0.3-0.2-0.6 0.1-0.8s0.6-0.2 0.8 0.1c1.4 1.5 3.1 2.8 4.9 3.8 0.3 0.2 0.4 0.5 0.2 0.8-0.1 0.1-0.3 0.2-0.5 0.2z" />
                    <path d="m412.2 48.5c-0.1 0-0.2 0-0.3-0.1-1.9-1.1-3.7-2.4-5.2-4-0.2-0.3-0.2-0.6 0.1-0.8s0.6-0.2 0.8 0.1c1.4 1.5 3.1 2.8 4.9 3.8 0.3 0.2 0.4 0.5 0.2 0.8-0.1 0.1-0.3 0.2-0.5 0.2z" />
                    <path d="m410.7 50c-0.1 0-0.2 0-0.3-0.1-1.9-1.1-3.7-2.4-5.2-4-0.2-0.3-0.2-0.6 0.1-0.8s0.6-0.2 0.8 0.1c1.4 1.5 3.1 2.8 4.9 3.8 0.3 0.2 0.4 0.5 0.2 0.8-0.1 0.1-0.3 0.2-0.5 0.2z" />
                    <path d="m409.2 51.5c-0.1 0-0.2 0-0.3-0.1-1.9-1.1-3.7-2.4-5.2-4-0.2-0.3-0.2-0.6 0.1-0.8s0.6-0.2 0.8 0.1c1.4 1.5 3.1 2.8 4.9 3.8 0.3 0.2 0.4 0.5 0.2 0.8-0.1 0.1-0.3 0.2-0.5 0.2z" />
                </g>
                <circle cx="3.4" cy="24.7" r="3.1" fill="#FFF" stroke="currentColor" strokeMiterlimit="10" strokeWidth=".4" />
                <rect x="1.4" y="24.2" width="4.1" height="1" />
                <rect transform="rotate(-90 3.4106 24.688)" x="1.4" y="24.2" width="4.1" height="1" />
            </g>
        </switch>
    </SvgIcon>
}

function RequestModifyPackageIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <switch transform="matrix(.63573 0 0 .63573 1.9364 2.5809)">
            <g transform="translate(-399.22 -25.383)">
                <g transform="translate(399.82 25.984)" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <g id="icons" transform="matrix(.68398 0 0 .68398 -.499 .53559)">
                        <path d="m23.25 20a12 12 0 1 1-2.25 7v-3" />
                        <polyline points="25 28 21 24 17 28" />
                    </g>
                    <g id="icons" transform="matrix(.68398 0 0 .68398 -.499 .53559)" strokeLinecap="round" strokeMiterlimit="10">
                        <polyline points="23 39 1 39 1 1 40 1 40 13" />
                        <rect x="15" y="1" width="11" height="5" />
                        <line x1="5" x2="7" y1="35" y2="35" />
                        <line x1="9" x2="11" y1="35" y2="35" />
                    </g>
                </g>
            </g>
        </switch>
    </SvgIcon>
}

function RequestRemovePartsIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <switch transform="matrix(.63573 0 0 .63573 1.9364 2.5809)">
            <g>
                <g transform="translate(-399.22 -25.383)">
                    <g transform="translate(399.82 25.984)">
                        <g transform="translate(2.709)">
                            <g transform="translate(0,5.229)">
                                <path d="m3.4 22.5c-0.5 0-0.9-0.2-1.3-0.5l-2.2-2.2c-0.7-0.7-0.7-1.8 0-2.5l17.7-17.7c0.2-0.2 0.6-0.2 0.8 0s0.2 0.6 0 0.8l-17.6 17.7c-0.2 0.2-0.2 0.6 0 0.8l2.2 2.2c0.2 0.2 0.6 0.2 0.8 0l17.7-17.6c0.2-0.2 0.6-0.2 0.8 0s0.2 0.6 0 0.8l-17.6 17.7c-0.3 0.3-0.8 0.5-1.3 0.5z" />
                            </g>
                            <g transform="translate(16.117)">
                                <path d="m7.9 11.6c-0.3 0-0.6-0.1-0.8-0.3l-7.4-7.4c-0.4-0.4-0.4-1.1 0-1.6l2.7-2.7c0.4-0.4 1.1-0.4 1.6 0l7.4 7.4c0.4 0.4 0.4 1.1 0 1.6l-2.7 2.7c-0.3 0.2-0.5 0.3-0.8 0.3zm-7.3-8.4 7.2 7.2 2.5-2.5-7.1-7.3z" />
                            </g>
                            <g transform="translate(17.779,1.662)">
                                <path d="m0 4c-0.2 0-0.3-0.1-0.4-0.2-0.2-0.2-0.2-0.6 0-0.8l3.4-3.4c0.2-0.2 0.6-0.2 0.8 0s0.2 0.6 0 0.8l-3.4 3.4c-0.1 0.1-0.2 0.2-0.4 0.2z" />
                            </g>
                            <g transform="translate(21.507,5.39)">
                                <path d="m0 4c-0.2 0-0.3-0.1-0.4-0.2-0.2-0.2-0.2-0.6 0-0.8l3.4-3.4c0.2-0.2 0.6-0.2 0.8 0s0.2 0.6 0 0.8l-3.4 3.4c-0.1 0.1-0.2 0.2-0.4 0.2z" />
                            </g>
                        </g>
                        <g transform="translate(0 .856)">
                            <path d="m3.6-0.6h6.7c0.3 0 0.6 0.2 0.7 0.4l3.3 5.8c0.1 0.3 0.1 0.6 0 0.8l-3.3 5.8c-0.1 0.3-0.4 0.4-0.7 0.4h-6.7c-0.3 0-0.6-0.2-0.7-0.4l-3.3-5.8c-0.1-0.3-0.1-0.6 0-0.8l3.3-5.8c0.1-0.2 0.4-0.4 0.7-0.4zm6.5 1.2h-6.3l-3.1 5.4 3.1 5.4h6.3l3.1-5.4z" />
                            <g transform="translate(3.694,2.799)">
                                <path d="m3.2-0.6c2.1 0 3.8 1.7 3.8 3.8s-1.7 3.9-3.8 3.9-3.8-1.7-3.8-3.8c0-2.2 1.7-3.9 3.8-3.9zm0 6.5c1.5 0 2.6-1.2 2.6-2.6 0-1.5-1.2-2.6-2.6-2.6-1.5 0-2.6 1.2-2.6 2.6s1.2 2.6 2.6 2.6z" />
                            </g>
                        </g>
                        <g transform="translate(16.586,16.531)">
                            <path d="m3.6-0.6h6.7c0.3 0 0.6 0.2 0.7 0.4l3.3 5.8c0.1 0.3 0.1 0.6 0 0.8l-3.3 5.8c-0.2 0.3-0.4 0.4-0.7 0.4h-6.7c-0.3 0-0.6-0.2-0.7-0.4l-3.3-5.8c-0.1-0.3-0.1-0.6 0-0.8l3.3-5.8c0.1-0.2 0.4-0.4 0.7-0.4zm6.5 1.2h-6.3l-3.1 5.4 3.1 5.4h6.3l3.1-5.4z" />
                            <g transform="translate(3.694,2.799)">
                                <path d="m3.2-0.6c2.1 0 3.8 1.7 3.8 3.8s-1.7 3.9-3.8 3.9-3.8-1.7-3.8-3.8c0-2.2 1.7-3.9 3.8-3.9zm0 6.5c1.5 0 2.6-1.2 2.6-2.6 0-1.5-1.2-2.6-2.6-2.6-1.5 0-2.6 1.2-2.6 2.6s1.2 2.6 2.6 2.6z" />
                            </g>
                        </g>
                    </g>
                    <path d="m421.2 39.4c-0.1 0-0.2 0-0.3-0.1-1.9-1.1-3.7-2.4-5.2-4-0.2-0.3-0.2-0.6 0.1-0.8s0.6-0.2 0.8 0.1c1.4 1.5 3.1 2.8 4.9 3.8 0.3 0.2 0.4 0.5 0.2 0.8 0 0.1-0.2 0.2-0.5 0.2z" />
                    <path d="m419.7 40.9c-0.1 0-0.2 0-0.3-0.1-1.9-1.1-3.7-2.4-5.2-4-0.2-0.3-0.2-0.6 0.1-0.8s0.6-0.2 0.8 0.1c1.4 1.5 3.1 2.8 4.9 3.8 0.3 0.2 0.4 0.5 0.2 0.8-0.1 0.1-0.3 0.2-0.5 0.2z" />
                    <path d="m418.2 42.5c-0.1 0-0.2 0-0.3-0.1-1.9-1.1-3.7-2.4-5.2-4-0.2-0.3-0.2-0.6 0.1-0.8s0.6-0.2 0.8 0.1c1.4 1.5 3.1 2.8 4.9 3.8 0.3 0.2 0.4 0.5 0.2 0.8-0.1 0-0.3 0.2-0.5 0.2z" />
                    <path d="m416.7 44c-0.1 0-0.2 0-0.3-0.1-1.9-1.1-3.7-2.4-5.2-4-0.2-0.3-0.2-0.6 0.1-0.8s0.6-0.2 0.8 0.1c1.4 1.5 3.1 2.8 4.9 3.8 0.3 0.2 0.4 0.5 0.2 0.8-0.1 0-0.3 0.2-0.5 0.2z" />
                    <path d="m415.2 45.5c-0.1 0-0.2 0-0.3-0.1-1.9-1.1-3.7-2.4-5.2-4-0.2-0.3-0.2-0.6 0.1-0.8s0.6-0.2 0.8 0.1c1.4 1.5 3.1 2.8 4.9 3.8 0.3 0.2 0.4 0.5 0.2 0.8-0.1 0.1-0.3 0.2-0.5 0.2z" />
                    <path d="m413.7 47c-0.1 0-0.2 0-0.3-0.1-1.9-1.1-3.7-2.4-5.2-4-0.2-0.3-0.2-0.6 0.1-0.8s0.6-0.2 0.8 0.1c1.4 1.5 3.1 2.8 4.9 3.8 0.3 0.2 0.4 0.5 0.2 0.8-0.1 0.1-0.3 0.2-0.5 0.2z" />
                    <path d="m412.2 48.5c-0.1 0-0.2 0-0.3-0.1-1.9-1.1-3.7-2.4-5.2-4-0.2-0.3-0.2-0.6 0.1-0.8s0.6-0.2 0.8 0.1c1.4 1.5 3.1 2.8 4.9 3.8 0.3 0.2 0.4 0.5 0.2 0.8-0.1 0.1-0.3 0.2-0.5 0.2z" />
                    <path d="m410.7 50c-0.1 0-0.2 0-0.3-0.1-1.9-1.1-3.7-2.4-5.2-4-0.2-0.3-0.2-0.6 0.1-0.8s0.6-0.2 0.8 0.1c1.4 1.5 3.1 2.8 4.9 3.8 0.3 0.2 0.4 0.5 0.2 0.8-0.1 0.1-0.3 0.2-0.5 0.2z" />
                    <path d="m409.2 51.5c-0.1 0-0.2 0-0.3-0.1-1.9-1.1-3.7-2.4-5.2-4-0.2-0.3-0.2-0.6 0.1-0.8s0.6-0.2 0.8 0.1c1.4 1.5 3.1 2.8 4.9 3.8 0.3 0.2 0.4 0.5 0.2 0.8-0.1 0.1-0.3 0.2-0.5 0.2z" />
                </g>
                <circle cx="3.4" cy="24.7" r="3.1" fill="#FFF" stroke="currentColor" strokeMiterlimit="10" strokeWidth=".4" />
                <rect x="1.4" y="24.2" width="4.1" height="1" />
            </g>
        </switch>
    </SvgIcon>
}

function RequestReceiveListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <switch transform="matrix(.5402 0 0 .5402 2.3571 -.78676)">
            <g transform="translate(-139.44 -129.52)" fill="none" stroke="currentColor" strokeWidth="2">
                <g id="Icons" transform="matrix(.5929 0 0 .5929 141.74 134.61)" strokeLinejoin="round">
                    <path d="m38.08 8.93a2.48 2.48 0 0 0-2.48-2.49h-6.4a1.11 1.11 0 0 1-1.08-0.83 5.61 5.61 0 0 0-11 0 1.11 1.11 0 0 1-1.12 0.83h-6.4a2.48 2.48 0 0 0-2.48 2.49v6.77h31z" />
                    <rect x="6.92" y="21.95" width="4.74" height="4.74" />
                    <line x1="15.34" x2="38.08" y1="24.32" y2="24.32" />
                    <rect x="6.92" y="33.74" width="4.74" height="4.74" />
                    <line x1="15.34" x2="31.53" y1="36.11" y2="36.11" />
                    <rect x="6.92" y="45.53" width="4.74" height="4.74" />
                    <line x1="15.34" x2="27.29" y1="47.9" y2="47.9" />
                    <polyline points="29.22 58.36 1 58.36 1 10.39 5.27 10.39" />
                    <polyline points="41.45 10.39 44.31 10.39 44.31 33.74" />
                </g>
                <g id="Icons" transform="matrix(.5929 0 0 .5929 141.74 134.61)" strokeLinecap="round" strokeMiterlimit="10">
                    <line x1="22.5" x2="22.7" y1="6.36" y2="6.36" />
                </g>
                <g id="Icons" transform="matrix(.5929 0 0 .5929 141.74 134.61)" strokeLinejoin="round" strokeLinecap="round">
                    <polyline points="36.26 50.52 38.94 53.34 45.65 46.3" />
                    <circle cx="40.96" cy="49.82" r="11.73" />
                </g>
            </g>
        </switch>
    </SvgIcon>
}

function MasterDownloadUploadIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.62002 0 0 .62002 -227.04 -29.113)" fill="none" stroke="currentColor" strokeWidth="1.2" data-name="组 171">
            <path transform="translate(-3.31)" d="m388.82 68.3 5.676-6.622h-2.838c0-5.647-3.294-9.664-10.406-10.375 5.142 2.646 4.73 5.434 4.73 10.375h-2.838z" strokeLinejoin="round" data-name="路径 310" />
            <g strokeMiterlimit="10">
                <path transform="translate(0,-5.206)" d="m370 74.647v-1.412l3.529-4.235h5.647" data-name="路径 311" />
                <path transform="translate(-9.119,-5.206)" d="m410.18 74.647v-1.412l-3.529-4.235h-5.647" data-name="路径 312" />
                <path transform="translate(0,-7.56)" d="m392.59 77-2.117 2.117h-9.882l-2.118-2.117h-8.47v10.587h31.057v-10.587z" data-name="路径 313" />
            </g>
        </g>
    </SvgIcon>
}

function PortListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.6341 0 0 .63409 -231.6 -29.102)" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="1.2" data-name="组 159">
            <path transform="translate(-10.789,-2.36)" d="m401 59h6.629v1.989h1.326v2.651h-1.326v3.977" data-name="路径 238" />
            <line transform="translate(396,51.993)" y2="5" data-name="直线 70" />
            <line transform="translate(393,54.993)" y2="2" data-name="直线 71" />
            <path transform="translate(-5.057,-4.046)" d="m390.63 64h-6.629v3.977" data-name="路径 239" />
            <path transform="translate(-8.429,-4.046)" d="m400.63 69.3v-5.3h-6.629v5.3" data-name="路径 240" />
            <path transform="translate(-1.686,-4.046)" d="m380.63 64h-6.629v3.977" data-name="路径 241" />
            <path transform="translate(-6.743,-1.686)" d="m395.63 61.64v-4.64h-6.629" data-name="路径 242" />
            <path transform="translate(-3.371,-1.686)" d="m385.63 61.64v-4.64h-6.629v4.64" data-name="路径 243" />
            <path transform="translate(0,-6.069)" d="m374.05 77.808c0-3.808-5.05-3.831-5.05-7.808h11.855l3.109 1.326h14.2v3.926a3.768 3.768 0 0 1-0.363 2.071" data-name="路径 244" />
            <path transform="translate(-1.901,-9.531)" d="m374.64 82.248 2.19-1.716a1.159 1.159 0 0 1 1.584 0.114l0.818 0.823a1.16 1.16 0 0 0 1.609 0.093l1.23-1.012a1.156 1.156 0 0 1 1.578 0.064l0.935 0.886a1.158 1.158 0 0 0 1.6 0.042l1.12-0.97a1.156 1.156 0 0 1 1.576 0.015l1.051 0.941a1.158 1.158 0 0 0 1.6-0.01l1.006-0.921a1.156 1.156 0 0 1 1.577-0.034l1.163 0.988a1.159 1.159 0 0 0 1.606-0.062l0.89-0.862a1.158 1.158 0 0 1 1.58-0.084l2.113 1.706" data-name="路径 245" />
            <path transform="translate(0,-11.8)" d="m369 87.713 0.573-0.449a1.157 1.157 0 0 1 1.583 0.114l0.818 0.822a1.159 1.159 0 0 0 1.609 0.094l1.23-1.012a1.157 1.157 0 0 1 1.578 0.064l0.935 0.886a1.158 1.158 0 0 0 1.605 0.042l1.12-0.97a1.156 1.156 0 0 1 1.576 0.015l1.051 0.941a1.158 1.158 0 0 0 1.6-0.01l1.006-0.921a1.155 1.155 0 0 1 1.576-0.034l1.163 0.988a1.158 1.158 0 0 0 1.606-0.062l0.89-0.862a1.156 1.156 0 0 1 1.58-0.083l2.113 1.706" data-name="路径 246" />
            <line transform="translate(379,66.993)" x2="1" data-name="直线 72" />
        </g>
    </SvgIcon>
}

function RoleListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.62162 0 0 .62162 -128.21 -28.054)" fill="none" stroke="currentColor" strokeWidth="1.2" data-name="组 163">
            <g strokeMiterlimit="10">
                <path transform="translate(0,-5.467)" d="m221.14 70.071v3.392h-8.141v-7.463h6.106" data-name="路径 268" />
                <path transform="translate(-.965 -5.467)" d="m216 69.392 2.035 2.035 4.749-5.427" data-name="路径 269" />
                <path transform="translate(0,-10.612)" d="m221.14 86.071v3.392h-8.141v-7.463h6.106" data-name="路径 270" />
                <path transform="translate(-.965 -10.612)" d="m216 85.392 2.035 2.035 4.749-5.427" data-name="路径 271" />
                <path transform="translate(0 -.322)" d="m221.14 54.071v3.392h-8.141v-7.463h6.106" data-name="路径 272" />
                <path transform="translate(-.965 -.322)" d="m216 53.392 2.035 2.035 4.749-5.427" data-name="路径 273" />
            </g>
            <g strokeLinejoin="round">
                <path transform="translate(-5.467,-4.18)" d="m240.86 74.212a2.861 2.861 0 0 0 2.714-2.714v-6.106c0-2.241-4.427-3.392-6.784-3.392s-6.785 1.151-6.785 3.392v6.108a2.861 2.861 0 0 0 2.714 2.714" data-name="路径 274" />
                <path transform="translate(-7.396)" d="m238.71 55.784a2.6 2.6 0 0 0 2.714-2.714v-1.356a2.714 2.714 0 1 0-5.427 0v1.357a2.6 2.6 0 0 0 2.713 2.713z" data-name="路径 275" />
                <line transform="translate(235.63 62.74)" y1="17.782" data-name="直线 86" />
                <line transform="translate(227.55 62.74)" y2="17.782" data-name="直线 87" />
                <line transform="translate(231.59,69.206)" y2="11.316" data-name="直线 88" />
            </g>
        </g>
    </SvgIcon>
}

function UserListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.64324 0 0 .64324 -234.64 -131.85)" fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="1.2" data-name="组 164">
            <path transform="translate(-2.817,-5.947)" d="m388.99 234.18v-2.748c0-2.269-3.587-3.435-5.5-3.435s-5.5 1.166-5.5 3.435v2.748" data-name="路径 276" />
            <path transform="translate(-4.069,-1.878)" d="m384.75 221.87a2.634 2.634 0 0 0 2.748-2.748v-1.374a2.75 2.75 0 1 0-5.5 0v1.374a2.634 2.634 0 0 0 2.752 2.748z" data-name="路径 277" />
            <circle transform="translate(369,209)" cx="11.679" cy="11.679" r="11.679" data-name="椭圆 58" />
            <path transform="translate(-8.451,-8.682)" d="m398.43 236.74 8.018 7.9a1.718 1.718 0 0 1 9e-3 2.438 1.717 1.717 0 0 1-2.42 9e-3l-8.035-7.918" data-name="路径 278" />
        </g>
    </SvgIcon>
}

function ProjectIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.28531 0 0 .28531 -9.257 -9.3303)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
            <path d="m109.03 105.77c-0.06 1.69-1.43 3.04-3.13 3.04h-0.48" />
            <path d="m109.04 105.66s0 0.07-0.01 0.11" />
            <path d="m97.54 56.06h8.37c1.74 0 3.14 1.41 3.14 3.14v7.18" />
            <path d="m40.97 105.77s-0.01-0.07-0.01-0.11" />
            <path d="m52.46 56.06h-8.37c-1.74 0-3.14 1.41-3.14 3.14v19.29" />
            <path d="m44.58 108.8h-0.48c-1.7 0-3.07-1.35-3.13-3.04" />
            <polyline points="97.54 56.06 97.54 41.2 52.46 41.2 52.46 56.06" />
            <line x1="97.54" x2="97.54" y1="63.25" y2="56.06" />
            <line x1="52.46" x2="52.46" y1="56.06" y2="75.37" />
            <line x1="82.41" x2="59.42" y1="65.65" y2="65.65" />
            <path d="m82.53 65.65-6.45 8.33c-0.67 0.87-1.8 1.39-3.01 1.39h-20.6" />
            <path d="m82.53 65.65 0.78-1.01c0.67-0.87 1.8-1.39 3.01-1.39h11.22" />
            <path d="m52.46 75.37h-7.89c-2 0-3.62 1.4-3.62 3.13" />
            <path d="m109.03 105.77c-0.06 1.68-1.65 3.04-3.61 3.04" />
            <path d="m109.04 105.66s0 0.07-0.01 0.11" />
            <path d="m97.54 63.25h7.89c2 0 3.62 1.4 3.62 3.13" />
            <path d="m44.58 108.8c-1.96 0-3.55-1.35-3.61-3.04" />
            <path d="m40.96 105.66s0 0.07 0.01 0.11" />
            <line x1="105.42" x2="44.58" y1="108.8" y2="108.8" />
            <line x1="40.96" x2="40.96" y1="78.5" y2="105.66" />
            <line x1="109.04" x2="109.04" y1="66.38" y2="105.66" />
            <line x1="59.42" x2="91.06" y1="52.22" y2="52.22" />
            <line x1="59.42" x2="91.06" y1="58.94" y2="58.94" />
        </g>
    </SvgIcon>
}

function StockHistoryIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.24119 0 0 .24119 -6.4955 -6.0892)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
            <path d="m90.03 71.51c3.95 3.15 9.16 4.7 14.54 3.86 1.42-0.22 2.77-0.61 4.05-1.12" />
            <path d="m86.14 46.39c2.82-4.19 7.31-7.24 12.7-8.08 10.23-1.58 19.81 5.43 21.4 15.66 1.36 8.81-3.65 17.13-11.61 20.28" />
            <line x1="90.27" x2="85.79" y1="46.92" y2="47.71" />
            <line x1="84.74" x2="84.99" y1="42.46" y2="47.08" />
            <polyline points="85.79 47.71 85.03 47.84 84.99 47.08" />
            <polyline points="101.53 49.7 101.53 56.87 107.63 59.72" />
            <path d="m94.45 65.42c1.98 1.68 4.54 2.7 7.34 2.7 2.57 0 4.94-0.87 6.84-2.31" />
            <path d="m91.44 52.09c-0.65 1.43-1.01 3-1.01 4.67 0 3.47 1.56 6.58 4.02 8.66" />
            <path d="m91.44 52.09c1.78-3.94 5.74-6.68 10.34-6.68 6.27 0 11.36 5.08 11.36 11.36 0 3.7-1.78 6.97-4.51 9.05" />
            <line x1="91.87" x2="39.54" y1="62.65" y2="62.65" />
            <line x1="57.21" x2="72.26" y1="112.79" y2="112.79" />
            <line x1="72.34" x2="87.39" y1="112.79" y2="112.79" />
            <polyline points="87.39 112.79 95.28 112.79 95.28 74.29 50.46 74.29 50.46 112.79 57.21 112.79" />
            <line x1="72.34" x2="72.26" y1="112.79" y2="112.79" />
            <polyline points="72.26 112.79 72.26 98.04 66.61 98.04" />
            <polyline points="57.21 112.79 57.21 113.08 72.26 113.08 72.26 112.79" />
            <polyline points="57.21 112.79 57.21 98.04 62.85 98.04" />
            <polyline points="62.85 98.04 62.85 103.05 66.61 103.05 66.61 98.04" />
            <line x1="66.61" x2="62.85" y1="98.04" y2="98.04" />
            <polyline points="72.34 112.79 72.34 113.08 87.39 113.08 87.39 112.79" />
            <polyline points="87.39 112.79 87.39 98.04 81.74 98.04" />
            <polyline points="72.34 112.79 72.34 98.04 77.98 98.04" />
            <polyline points="77.98 98.04 77.98 103.05 81.74 103.05 81.74 98.04" />
            <line x1="81.74" x2="77.98" y1="98.04" y2="98.04" />
            <polyline points="70.82 82.23 65.17 82.23 65.17 97.28 80.22 97.28 80.22 82.23 74.58 82.23" />
            <polyline points="70.82 82.23 70.82 87.25 74.58 87.25 74.58 82.23" />
            <line x1="70.82" x2="74.58" y1="82.23" y2="82.23" />
            <polyline points="106.2 75.6 106.2 113.21 39.54 113.21 39.54 62.65" />
            <line x1="85.69" x2="84.97" y1="46.73" y2="46.17" />
            <polyline points="84.97 46.17 72.87 36.79 39.54 62.65" />
        </g>
    </SvgIcon>
}

function DownloadUploadCustomerUsageIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.60241 0 0 .60241 -363.3 -213.35)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" data-name="组 4080">
            <ellipse transform="translate(617 373.5)" cx="2" cy="1.5" rx="2" ry="1.5" data-name="椭圆 193" />
            <circle transform="translate(627 373.5)" cx="1.5" cy="1.5" r="1.5" data-name="椭圆 194" />
            <line transform="translate(621 374.5)" x2="6" data-name="直线 899" />
            <path transform="translate(-3.749)" d="m619 360h2.406l1.2 12.618" data-name="路径 1901" />
            <path transform="translate(-4.999 -.857)" d="m623 362.92 12.7-0.176a0.694 0.694 0 0 1 0.707 0.757l-0.569 5.586a0.688 0.688 0 0 1-0.581 0.611l-8.586 1.3" data-name="路径 1902" />
            <path transform="translate(-12.185,-2.499)" d="m646 368h1.375a1.4 1.4 0 0 1 1.375 1.428v17.136" data-name="路径 1903" />
            <path transform="translate(-.937 -2.499)" d="m610 386.56v-17.136a1.4 1.4 0 0 1 1.375-1.428h5.5" data-name="路径 1904" />
            <path transform="translate(607 384.5)" d="m0.5 0h31a0.5 0.5 0 0 1 0.5 0.5 2.5 2.5 0 0 1-2.5 2.5h-27a2.5 2.5 0 0 1-2.5-2.5 0.5 0.5 0 0 1 0.5-0.5z" data-name="矩形 14405" />
            <line transform="translate(621.78 385.44)" x2="2.063" data-name="直线 900" />
        </g>
    </SvgIcon>
}

function WeeklyStockIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.26596 0 0 .26596 -7.9468 -7.8755)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
            <rect x="38.4" y="45.76" width="65.88" height="56.16" />
            <line x1="52.36" x2="52.36" y1="49.79" y2="41.01" />
            <line x1="90.51" x2="90.51" y1="49.52" y2="40.73" />
            <rect x="45.11" y="66.86" width="8.5" height="8.5" />
            <rect x="59.63" y="66.86" width="8.5" height="8.5" />
            <rect x="74.15" y="66.86" width="8.5" height="8.5" />
            <rect x="88.67" y="66.86" width="8.5" height="8.5" />
            <rect x="45.11" y="82.96" width="8.5" height="8.5" />
            <rect x="59.63" y="82.96" width="8.5" height="8.5" />
            <rect x="74.15" y="82.96" width="8.5" height="8.5" />
            <rect x="88.67" y="82.96" width="8.5" height="8.5" />
            <line x1="38.4" x2="104.28" y1="57.7" y2="57.7" />
            <polyline points="104.28 53.11 111.6 53.11 111.6 109.27 45.72 109.27 45.72 102.44" />
        </g>
    </SvgIcon>
}

function DailyRundownIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.26254 0 0 .26254 -7.6244 -7.6592)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
            <rect x="40.68" y="43.01" width="62.16" height="62.73" />
            <line x1="40.68" x2="102.85" y1="56.3" y2="56.3" />
            <line x1="53.55" x2="53.55" y1="47.83" y2="37.91" />
            <line x1="66.05" x2="66.05" y1="47.83" y2="37.91" />
            <line x1="78.56" x2="78.56" y1="47.83" y2="37.91" />
            <line x1="91.07" x2="91.07" y1="47.83" y2="37.91" />
            <polyline points="102.85 49.37 109.32 49.37 109.32 112.09 47.15 112.09 47.15 105.74" />
            <path fill="currentColor" d="m61.52 91.9h-3.85v-23.23l-3.9 2.39v-3.98l3.9-2.6h3.85v27.41z" />
            <path fill="currentColor" d="m73.05 90.13c-1.33-1.37-1.99-3.28-1.99-5.73v-12.46c0-2.46 0.67-4.38 2-5.74s3.27-2.05 5.83-2.05 4.51 0.68 5.83 2.04 1.99 3.27 1.99 5.75v12.46c0 2.46-0.67 4.38-2 5.74s-3.27 2.05-5.83 2.05-4.51-0.69-5.83-2.06zm8.84-2.67c0.65-0.71 0.97-1.73 0.97-3.06v-12.46c0-1.33-0.32-2.35-0.96-3.06s-1.65-1.07-3.02-1.07-2.38 0.36-3.02 1.07-0.96 1.73-0.96 3.06v12.46c0 1.33 0.32 2.35 0.97 3.06s1.65 1.07 3.01 1.07 2.36-0.35 3.01-1.07z" />
        </g>
    </SvgIcon>
}

function CustomerStockIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.255 0 0 .255 -7.125 -7.2266)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
            <rect x="41.67" y="62.65" width="66.66" height="50.57" />
            <rect x="52.59" y="74.29" width="44.82" height="38.5" />
            <rect x="59.34" y="98.04" width="15.05" height="15.05" />
            <rect x="64.98" y="98.04" width="3.76" height="5.02" />
            <rect x="74.47" y="98.04" width="15.05" height="15.05" />
            <rect x="80.11" y="98.04" width="3.76" height="5.02" />
            <rect x="67.3" y="82.23" width="15.05" height="15.05" />
            <rect x="72.94" y="82.23" width="3.76" height="5.02" />
            <polygon points="75 36.79 108.33 62.65 108.33 113.21 41.67 113.21 41.67 62.65" />
        </g>
    </SvgIcon>
}

function StockAdjustmentIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.21971 0 0 .21971 -5.2762 -4.5826)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
            <rect x="51.4" y="75.89" width="44.82" height="38.5" />
            <rect x="58.15" y="99.64" width="15.05" height="15.05" />
            <rect x="63.79" y="99.64" width="3.76" height="5.02" />
            <rect x="73.28" y="99.64" width="15.05" height="15.05" />
            <rect x="78.92" y="99.64" width="3.76" height="5.02" />
            <rect x="66.12" y="83.84" width="15.05" height="15.05" />
            <rect x="71.76" y="83.84" width="3.76" height="5.02" />
            <line x1="40.49" x2="83.67" y1="64.25" y2="64.25" />
            <polyline points="107.14 75.18 107.14 114.82 40.49 114.82 40.49 64.25" />
            <polyline points="83.67 46.04 73.82 38.39 40.49 64.25" />
            <path d="m120 50.54v19.74c0 2.69-2.18 4.88-4.88 4.88h-3.94" />
            <path d="m111.18 75.15h-22.64c-2.69 0-4.88-2.18-4.88-4.88v-7.1" />
            <line x1="83.67" x2="83.67" y1="46.04" y2="64.25" />
            <path d="m117.66 39.99c-0.86-0.74-1.31-1.17-2.53-1.17h-26.58c-2.69 0-4.88 2.18-4.88 4.88v1.76" />
            <line x1="120" x2="126.14" y1="48.85" y2="44.01" />
            <line x1="118.31" x2="121.22" y1="40.03" y2="37.75" />
            <line x1="104.12" x2="120" y1="61.33" y2="48.85" />
            <line x1="104.12" x2="103.71" y1="61.33" y2="61.66" />
            <line x1="118.31" x2="98.78" y1="40.03" y2="55.39" />
            <line x1="98.78" x2="98.3" y1="55.39" y2="56.81" />
            <polyline points="103.71 61.66 96.43 62.31 98.3 56.81" />
            <line x1="98.78" x2="102.41" y1="55.39" y2="60" />
            <line x1="103.71" x2="102.41" y1="61.66" y2="60" />
            <path d="m121.22 37.75 3-2.36c0.43-0.33 1.04-0.26 1.38 0.16l3.72 4.72c0.33 0.43 0.26 1.04-0.16 1.38l-3 2.36" />
            <line x1="121.22" x2="126.14" y1="37.75" y2="44.01" />
            <line x1="119.2" x2="123.84" y1="39.33" y2="45.22" />
        </g>
    </SvgIcon>
}

function OrderCalculationGroupingIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.072748 0 0 .072748 -2.4448 -2.2628)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
            <g strokeWidth="3.7795">
                <rect x="123.35" y="98.777" width="22.224" height="22.224" />
                <line x1="164.73" x2="248.26" y1="101.57" y2="101.57" />
                <line x1="164.73" x2="256.01" y1="117.86" y2="117.86" />
                <rect x="123.35" y="145.98" width="22.224" height="22.224" />
                <line x1="164.73" x2="203.81" y1="148.78" y2="148.78" />
                <line x1="164.73" x2="203.81" y1="165.07" y2="165.07" />
                <rect x="123.35" y="193.23" width="22.224" height="22.224" />
                <line x1="164.73" x2="203.81" y1="196.02" y2="196.02" />
                <line x1="164.73" x2="203.81" y1="212.31" y2="212.31" />
                <rect x="123.35" y="240.43" width="22.224" height="22.224" />
                <line x1="164.73" x2="203.81" y1="243.23" y2="243.23" />
                <line x1="164.73" x2="203.81" y1="259.52" y2="259.52" />
            </g>
            <polyline transform="matrix(3.7795 0 0 3.7795 -93.408 -86.457)" points="59.09 50.69 61 52.79 66.04 47.75" />
            <polyline transform="matrix(3.7795 0 0 3.7795 -93.408 -86.457)" points="59.44 63.39 61.35 65.49 66.39 60.45" />
            <polyline transform="matrix(3.7795 0 0 3.7795 -93.408 -86.457)" points="59.44 75.99 61.35 78.09 66.39 73.05" />
            <polyline transform="matrix(3.7795 0 0 3.7795 -93.408 -86.457)" points="59.44 88.58 61.35 90.68 66.39 85.64" />
            <path d="m288.63 139.56h-76.686c-5.5559 0-10.054 4.4976-10.054 10.054v163.46c0 5.5559 4.4976 10.054 10.054 10.054h76.686" strokeWidth="7.559" />
            <path d="m288.63 139.56h33.562c5.5559 0 10.054 4.4976 10.054 10.054v163.46c0 5.5559-4.4976 10.054-10.054 10.054h-33.562" strokeWidth="7.559" />
            <g strokeWidth="3.7795">
                <path d="m288.63 157.93h21.203c2.7212 0 4.9512 2.2299 4.9512 4.9512v19.464c0 2.7212-2.2299 4.9512-4.9512 4.9512h-21.203" />
                <path d="m288.63 157.93h-64.327c-2.7212 0-4.9512 2.2299-4.9512 4.9512v19.464c0 2.7212 2.2299 4.9512 4.9512 4.9512h64.327" />
                <rect x="221.39" y="201.96" width="20.183" height="20.183" />
                <rect x="257.56" y="201.96" width="20.183" height="20.183" />
                <rect x="294.37" y="201.96" width="20.183" height="20.183" />
                <rect x="221.39" y="239.79" width="20.183" height="20.183" />
                <rect x="257.56" y="239.79" width="20.183" height="20.183" />
                <rect x="294.37" y="238.69" width="20.183" height="58.734" />
                <rect x="221.39" y="277.62" width="20.183" height="20.183" />
                <rect x="257.56" y="277.62" width="20.183" height="20.183" />
            </g>
            <polyline transform="matrix(3.7795 0 0 3.7795 -93.408 -86.457)" points="82.94 40.19 98.79 40.19 98.79 46.07 98.79 59.46" strokeWidth="2" />
            <polyline transform="matrix(3.7795 0 0 3.7795 -93.408 -86.457)" points="83.68 40.19 50.93 40.19 50.93 100.65 77.44 100.65" strokeWidth="2" />
            <polyline transform="matrix(3.7795 0 0 3.7795 -93.408 -86.457)" points="50.54 45.27 46.27 45.27 46.27 105.73 77.84 105.73" />
            <polyline transform="matrix(3.7795 0 0 3.7795 -93.408 -86.457)" points="45.65 49.35 41.38 49.35 41.38 109.81 89.25 109.81 89.25 108.77" />
        </g>
    </SvgIcon>
}

function OrderCalculationListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.26254 0 0 .26254 -7.6909 -7.6902)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1">
            <rect x="47.4" y="48.73" width="7.21" height="7.21" />
            <line x1="60.84" x2="87.94" y1="49.64" y2="49.64" />
            <line x1="60.84" x2="90.46" y1="54.92" y2="54.92" />
            <rect x="47.4" y="64.06" width="7.21" height="7.21" />
            <line x1="60.84" x2="73.52" y1="64.97" y2="64.97" />
            <line x1="60.84" x2="73.52" y1="70.25" y2="70.25" />
            <rect x="47.4" y="79.39" width="7.21" height="7.21" />
            <line x1="60.84" x2="73.52" y1="80.3" y2="80.3" />
            <line x1="60.84" x2="73.52" y1="85.58" y2="85.58" />
            <rect x="47.4" y="94.72" width="7.21" height="7.21" />
            <line x1="60.84" x2="73.52" y1="95.63" y2="95.63" />
            <line x1="60.84" x2="73.52" y1="100.91" y2="100.91" />
            <polyline points="49.54 50.79 51.88 53.36 58.06 47.18" />
            <polyline points="49.96 66.37 52.31 68.95 58.49 62.77" />
            <polyline points="49.96 81.83 52.31 84.4 58.49 78.22" />
            <polyline points="49.96 97.28 52.31 99.86 58.49 93.68" />
            <path strokeWidth="2" d="m98.25 56.97h-21.52c-1.56 0-2.82 1.26-2.82 2.82v45.88c0 1.56 1.26 2.82 2.82 2.82h21.52" />
            <path strokeWidth="2" d="m98.25 56.97h9.42c1.56 0 2.82 1.26 2.82 2.82v45.88c0 1.56-1.26 2.82-2.82 2.82h-9.42" />
            <path d="m98.25 62.12h5.95c0.77 0 1.39 0.62 1.39 1.39v5.46c0 0.77-0.62 1.39-1.39 1.39h-5.95" />
            <path d="m98.25 62.12h-18.06c-0.77 0-1.39 0.62-1.39 1.39v5.46c0 0.77 0.62 1.39 1.39 1.39h18.06" />
            <rect x="79.37" y="74.48" width="5.67" height="5.67" />
            <rect x="89.52" y="74.48" width="5.67" height="5.67" />
            <rect x="99.85" y="74.48" width="5.67" height="5.67" />
            <rect x="79.37" y="85.1" width="5.67" height="5.67" />
            <rect x="89.52" y="85.1" width="5.67" height="5.67" />
            <rect x="99.85" y="84.79" width="5.67" height="16.49" />
            <rect x="79.37" y="95.72" width="5.67" height="5.67" />
            <rect x="89.52" y="95.72" width="5.67" height="5.67" />
            <polyline strokeWidth="2" points="78.8 37.91 98.25 37.91 98.25 45.12 98.25 56.45" />
            <polyline strokeWidth="2" points="79.7 37.91 39.52 37.91 39.52 112.09 98.25 112.09 98.25 109" />
        </g>
    </SvgIcon>
}

function OrderCalculationSettingIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.20627 0 0 .20627 -4.2618 -2.4842)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1">
            <rect x="46.33" y="53.36" width="7.21" height="7.21" />
            <line x1="59.77" x2="86.88" y1="54.27" y2="54.27" />
            <line x1="59.77" x2="89.39" y1="59.55" y2="59.55" />
            <rect x="46.33" y="68.69" width="7.21" height="7.21" />
            <line x1="59.77" x2="72.45" y1="69.6" y2="69.6" />
            <line x1="59.77" x2="72.45" y1="74.89" y2="74.89" />
            <rect x="46.33" y="84.02" width="7.21" height="7.21" />
            <line x1="59.77" x2="72.45" y1="84.93" y2="84.93" />
            <line x1="59.77" x2="72.45" y1="90.22" y2="90.22" />
            <rect x="46.33" y="99.35" width="7.21" height="7.21" />
            <line x1="59.77" x2="72.45" y1="100.26" y2="100.26" />
            <line x1="59.77" x2="72.45" y1="105.55" y2="105.55" />
            <polyline points="48.47 55.42 50.81 58 57 51.82" />
            <polyline points="48.9 71.01 51.24 73.58 57.42 67.4" />
            <polyline points="48.9 86.46 51.24 89.04 57.42 82.86" />
            <polyline points="48.9 101.92 51.24 104.49 57.42 98.31" />
            <path strokeWidth="2" d="m97.18 61.6h-21.52c-1.56 0-2.82 1.26-2.82 2.82v45.88c0 1.56 1.26 2.82 2.82 2.82h21.52" />
            <path strokeWidth="2" d="m97.18 61.6h9.42c1.56 0 2.82 1.26 2.82 2.82v45.88c0 1.56-1.26 2.82-2.82 2.82h-9.42" />
            <path d="m97.18 66.76h5.95c0.77 0 1.39 0.62 1.39 1.39v5.46c0 0.77-0.62 1.39-1.39 1.39h-5.95" />
            <path d="m97.18 66.76h-18.06c-0.77 0-1.39 0.62-1.39 1.39v5.46c0 0.77 0.62 1.39 1.39 1.39h18.06" />
            <rect x="78.3" y="79.12" width="5.67" height="5.67" />
            <rect x="88.46" y="79.12" width="5.67" height="5.67" />
            <rect x="98.79" y="79.12" width="5.67" height="5.67" />
            <rect x="78.3" y="89.74" width="5.67" height="5.67" />
            <rect x="88.46" y="89.74" width="5.67" height="5.67" />
            <rect x="98.79" y="89.42" width="5.67" height="16.49" />
            <rect x="78.3" y="100.36" width="5.67" height="5.67" />
            <rect x="88.46" y="100.36" width="5.67" height="5.67" />
            <line strokeWidth="2" x1="96.15" x2="85.85" y1="42.54" y2="42.54" />
            <line strokeWidth="2" x1="97.18" x2="97.18" y1="49.76" y2="61.09" />
            <polyline strokeWidth="2" points="78.63 42.54 38.45 42.54 38.45 116.73 97.18 116.73 97.18 113.64" />
            <path d="m97.18 42.54c0.93-1.24 1.49-2.78 1.49-4.45 0-4.12-3.34-7.45-7.45-7.45s-7.45 3.34-7.45 7.45c0 1.67 0.56 3.21 1.49 4.45" />
            <path d="m85.25 42.54c1.36 1.82 3.52 3 5.97 3s4.61-1.18 5.97-3" />
            <path d="m79.34 42.54c-0.12-0.31-0.24-0.62-0.33-0.94h-3.02c-0.88 0-1.59-0.71-1.59-1.59v-4.23c0-0.88 0.71-1.59 1.59-1.59h3.02c0.26-0.86 0.62-1.68 1.05-2.45l-2.12-2.12c-0.62-0.62-0.62-1.63 0-2.25l2.99-2.99c0.62-0.62 1.63-0.62 2.25 0l2.23 2.23c0.64-0.32 1.31-0.6 2-0.81v-2.94c0-0.88 0.71-1.59 1.59-1.59h4.23c0.88 0 1.59 0.71 1.59 1.59v2.94c0.73 0.22 1.44 0.52 2.11 0.86l2.49-2.49c0.62-0.62 1.63-0.62 2.25 0l2.99 2.99c0.62 0.62 0.62 1.63 0 2.25l-2.43 2.43c0.41 0.74 0.74 1.53 0.99 2.35h3" />
            <path d="m79.34 42.54c0.16 0.4 0.33 0.79 0.53 1.17l-2 2c-0.62 0.62-0.62 1.63 0 2.25l2.99 2.99c0.62 0.62 1.63 0.62 2.25 0l1.94-1.94c0.74 0.41 1.53 0.74 2.35 0.99v3.32c0 0.88 0.71 1.59 1.59 1.59h4.23c0.88 0 1.59-0.71 1.59-1.59v-3.32c0.83-0.25 1.62-0.6 2.37-1.01" />
            <path d="m98.81 50.5-1.55-1.55-0.08 0.04" />
            <path d="m105.99 47.87c0.81 3.24 4.1 5.21 7.34 4.4s5.21-4.1 4.4-7.34-4.1-5.21-7.34-4.4-5.21 4.1-4.4 7.34z" />
            <path d="m106.2 34.2c0.16-0.32 0.45-0.57 0.82-0.67l3.33-0.84c0.69-0.17 1.39 0.25 1.57 0.94l0.6 2.38c0.73 0.04 1.45 0.15 2.14 0.34l1.25-2.09c0.37-0.61 1.16-0.81 1.77-0.44l2.95 1.76c0.61 0.37 0.81 1.16 0.44 1.77l-1.32 2.2c0.38 0.44 0.73 0.91 1.03 1.42l2.32-0.58c0.69-0.17 1.39 0.25 1.57 0.94l0.84 3.33c0.17 0.69-0.25 1.39-0.94 1.57l-2.32 0.58c-0.03 0.62-0.12 1.23-0.26 1.83l2.46 1.47c0.61 0.37 0.81 1.16 0.44 1.77l-1.76 2.95c-0.37 0.61-1.16 0.81-1.77 0.44l-2.4-1.44c-0.5 0.47-1.06 0.88-1.66 1.24l0.64 2.55c0.17 0.69-0.25 1.39-0.94 1.57l-3.33 0.84c-0.69 0.17-1.39-0.25-1.57-0.94l-0.64-2.55c-0.59-0.03-1.17-0.11-1.74-0.24l-1.36 2.28c-0.37 0.61-1.16 0.81-1.77 0.44l-2.95-1.76c-0.61-0.37-0.81-1.16-0.44-1.77l1.3-2.17c-0.49-0.52-0.93-1.1-1.31-1.72l-2.61 0.66c-0.69 0.17-1.39-0.25-1.57-0.94l-0.2-0.8" />
            <path d="m106.2 34.2c-0.14 0.27-0.2 0.58-0.12 0.9l0.6 2.38c-0.53 0.32-1.03 0.69-1.49 1.1l-1.97-1.18c-0.61-0.37-1.41-0.17-1.77 0.44l-1.76 2.95c-0.37 0.61-0.17 1.41 0.44 1.77l1.91 1.14c-0.17 0.66-0.28 1.35-0.31 2.05l-2.61 0.66c-0.69 0.17-1.11 0.88-0.94 1.57l0.63 2.53" />
        </g>
    </SvgIcon>
}

function ManageExternalDataIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.64324 0 0 .64324 -234.64 -131.85)" fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="1.2" data-name="组 164">
            <path transform="translate(-2.817,-5.947)" d="m388.99 234.18v-2.748c0-2.269-3.587-3.435-5.5-3.435s-5.5 1.166-5.5 3.435v2.748" data-name="路径 276" />
            <path transform="translate(-4.069,-1.878)" d="m384.75 221.87a2.634 2.634 0 0 0 2.748-2.748v-1.374a2.75 2.75 0 1 0-5.5 0v1.374a2.634 2.634 0 0 0 2.752 2.748z" data-name="路径 277" />
            <circle transform="translate(369,209)" cx="11.679" cy="11.679" r="11.679" data-name="椭圆 58" />
            <path transform="translate(-8.451,-8.682)" d="m398.43 236.74 8.018 7.9a1.718 1.718 0 0 1 9e-3 2.438 1.717 1.717 0 0 1-2.42 9e-3l-8.035-7.918" data-name="路径 278" />
        </g>
    </SvgIcon>
}


const useStyles = makeStyles(() => ({
    size: {
        height: 27,
        width: 27,
    },
    icon: {
        height: '100%',
        width: '100%'
    },
}))




